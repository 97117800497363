<div class="custom-timepicker-container form-group col-12 row">
    <label class="form-control-label truncate" for="time">{{label}}</label>
    <div class="input-group date luc-date">
        <input #custom_timepicker_input type="text" class="form-control" name="time" #dpHoraInicio="bsDatepicker"
            bsDatepicker [(ngModel)]="selectedTime" triggers=""
            [bsConfig]="{ dateInputFormat: 'HH:mm', containerClass: 'theme-dark-blue' }"
            (ngModelChange)="ngModelChange()">
        <span #custom_timepicker_button class="input-group-addon fa fa-clock" (click)="showHidePopup()">
            <div #custom_timepicker_popup [ngStyle]="seEstaMostrandoPopup?{}:{display:'none'}"
                class="popup-container">
                <timepicker name="time" [(ngModel)]="selectedTime" (ngModelChange)="ngModelChange()"
                    [showMeridian]="false"></timepicker>
            </div>
        </span>
        <span *ngIf="help" class="input-group-addon fa fa-question-circle" (click)="modalHelp.openModal();">
            <evtc-custom-modal #modalHelp [header]="label" [body]="help"></evtc-custom-modal>
        </span>
    </div>
</div>