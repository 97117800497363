import { IAreaTrabajo } from "../../entity/area-trabajo.interface";
import { IConductor } from "../../entity/conductor.interface";
import { IServicio } from "../../entity/servicio.interface";
import { IPermisoConduccion } from "../../entity/permiso-conduccion.interface";
import { IVehiculo } from "../../entity/vehiculo.interface";
import { DateUtils } from "../../utils/date.util";
import { EventoSchedulerReserva } from "../../helper/dhtmlxHelper/evento-scheduler-reserva.class";
import { EventoSchedulerBll } from "../evento-scheduler.bll";
import { IAgrupacionCompartidos } from "../../entity/agrupacion-compartidos.interface";
import { EventoScheduler } from "../../helper/dhtmlxHelper/evento-schedulerx";
import { TipoDataEventoScheduler } from "../../helper/dhtmlxHelper/evento-scheduler.interface";
import { EventoSchedulerMarcador } from "../../helper/dhtmlxHelper/evento-scheduler-marcador.class";

type OpcionesAsignacionRuta = {
    fechaHoraMin: Date;
    fechaHoraMax: Date;
    bloqueada: boolean;
    evitarEliminarParaRedistribucionAutomatica: boolean;
};

export class RutaEventos {
    private id: number;
    private eventos: EventoScheduler[];
    private conductorAsignado: IConductor | null;
    private areaTrabajo: IAreaTrabajo;
    private asignacionConfirmada: boolean;

    
    private opcionesAsignacionRuta: OpcionesAsignacionRuta;

    public constructor(
        id: number,
        eventos?: EventoScheduler[],
        conductor?: IConductor,
        opcionesAsignacionRuta?: OpcionesAsignacionRuta
    ) {
        this.id = id;
        this.asignacionConfirmada = false;
        this.setEventos(eventos ? eventos : []);
        this.setConductor(conductor ? conductor : null);

        this.opcionesAsignacionRuta = opcionesAsignacionRuta?
            opcionesAsignacionRuta:
            this.getOpcionesAsignacionRutaCalculadas();
    }

    private getOpcionesAsignacionRutaCalculadas(): OpcionesAsignacionRuta {
        return {
            fechaHoraMin: new Date(),
            fechaHoraMax: new Date(),
            bloqueada: false,
            evitarEliminarParaRedistribucionAutomatica: false
        }
    }

    public static fromJsonObject(objeto: any): RutaEventos {
        let ruta = new RutaEventos(objeto.id);
        Object.assign(ruta, objeto);
        ruta.setEventos(ruta.getEventos().map(evento => EventoSchedulerBll.fromJsonObject(evento)));

        return ruta;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public getEventos(): EventoScheduler[] {
        return this.eventos;
    }

    public getEventosReservas(): EventoSchedulerReserva[] {
        return this.getEventos()
            .filter(evento => evento instanceof EventoSchedulerReserva)
            .map(evento => evento as EventoSchedulerReserva);
    }

    public getEventosMarcadores(): EventoSchedulerMarcador[] {
        return this.getEventos()
            .filter(evento => evento instanceof EventoSchedulerMarcador)
            .map(evento => evento as EventoSchedulerMarcador);
    }

    public getOpcionesAsignacionRuta(): OpcionesAsignacionRuta {
        return this.opcionesAsignacionRuta;
    }

    public setEventos(eventos: EventoScheduler[]): void {
        this.eventos = eventos;

        this.actualizarAreaTrabajoRuta();
        this.actualizarConductoresEventos();
    }

    public addEvento(evento: EventoScheduler): void {
        this.eventos.push(evento);

        this.actualizarAreaTrabajoRuta();
        this.actualizarConductoresEventos();
    }

    public removeEvento(idEvento: string): void {
        let eventos = this.getEventos();
        let index = eventos.findIndex((evento) => evento.id === idEvento);

        if (index >= 0) {
            let eventoEncontrado = eventos[index];

            if(eventoEncontrado instanceof EventoSchedulerReserva)
                eventoEncontrado.asignarIdsConductores([]);
            
            eventos.splice(index, 1);
            this.actualizarAreaTrabajoRuta();
        }
    }

    public getConductor(): IConductor | null {
        return this.conductorAsignado;
    }

    public setConductor(conductor: IConductor): void {
        if (!conductor || this.esCompatibleConConductor(conductor)) {
            this.conductorAsignado = conductor;

            if (!conductor)
                this.desconfirmarAsignacion();

            this.actualizarAreaTrabajoRuta();
            this.actualizarConductoresEventos();
        }
    }

    public confirmarAsignacion() {
        this.asignacionConfirmada = true;
        this.actualizarConductoresEventos();
    }

    public desconfirmarAsignacion() {
        this.asignacionConfirmada = false;
        this.actualizarConductoresEventos();
    }

    public getConfirmacionAsignacion(): boolean {
        return this.asignacionConfirmada;
    }

    public getAreaTrabajo(): IAreaTrabajo | null {
        return this.areaTrabajo;
    }

    public redefinirEventosMarcadores() {
        let opcionesAsignacion = this.getOpcionesAsignacionRuta();
        const horasLongitudEventosMargenes = 1000;

        // Eliminamos eventos marcadores preexistentes
        this.getEventosMarcadores().forEach(evento => this.removeEvento(evento.id))

        let fechaMinEnd = new Date(opcionesAsignacion.fechaHoraMin);
        let fechaMinStart = new Date(fechaMinEnd);
        DateUtils.restarHoras(fechaMinStart, horasLongitudEventosMargenes);

        let eventoFechaMinRuta = new EventoSchedulerMarcador(
            'MARGEN_FECHA_MIN_RUTA_' + this.id,
            null,
            'R' + this.id,
            'FUERA DE LÍMITES',
            fechaMinStart,
            fechaMinEnd,
            '#6b6b6b'
        );

        let fechaMaxStart = new Date(opcionesAsignacion.fechaHoraMax);
        let fechaMaxEnd = new Date(fechaMaxStart);
        DateUtils.sumarHoras(fechaMaxEnd, horasLongitudEventosMargenes);

        let eventoFechaMaxRuta = new EventoSchedulerMarcador(
            'MARGEN_FECHA_MAX_RUTA_' + this.id,
            null,
            'R' + this.id,
            'FUERA DE LÍMITES',
            fechaMaxStart,
            fechaMaxEnd,
            '#6b6b6b'
        );

        this.addEvento(eventoFechaMinRuta);
        this.addEvento(eventoFechaMaxRuta);
    }

    public getPermisoConduccionMax(): IPermisoConduccion | null {
        let permisoConduccionMax: IPermisoConduccion = null;
        let eventosReservas = this.getEventosReservas();

        if (eventosReservas.length > 0) {
            let eventoConMayorPermCond = eventosReservas.filter(evento => evento.getTipoServicio() !== null)
                .reduce((eventoPrevio, eventoActual) =>
                    eventoPrevio.getTipoServicio().permisoConduccion.nivel > eventoActual.getTipoServicio().permisoConduccion.nivel ? eventoPrevio : eventoActual
                );

            permisoConduccionMax = eventoConMayorPermCond ? eventoConMayorPermCond.getTipoServicio().permisoConduccion : null;
        }

        return permisoConduccionMax;
    }

    private actualizarAreaTrabajoRuta() {
        let eventosReservas = this.getEventosReservas();
        let primerEventoConAreaTrabajo = eventosReservas.find(evento => evento.getAreaTrabajo() !== null);

        if (primerEventoConAreaTrabajo)
            this.areaTrabajo = primerEventoConAreaTrabajo.getAreaTrabajo();
        else if (this.conductorAsignado && this.conductorAsignado.areaTrabajo)
            this.areaTrabajo = this.conductorAsignado.areaTrabajo;
        else
            this.areaTrabajo = null;
    }

    private actualizarConductoresEventos() {
        let eventosReservas = this.getEventosReservas();
        let valorConductoresAsignados = (this.conductorAsignado && this.asignacionConfirmada) ?
            [this.conductorAsignado.id] :
            [];
        eventosReservas.forEach((evento) => evento.asignarIdsConductores(valorConductoresAsignados));
    }

    public getId(): number {
        return this.id;
    }

    public isEmpty(): boolean {
        return this.getEventosReservas().length === 0;
    }

    private existeEventoSolapadoEnHorario(evento: EventoSchedulerReserva): boolean {
        return this.getEventosReservas().some(eventoRuta => eventoRuta.estaSolapadoEnHorario(evento));
    }

    public sePuedeAsignarAutomaticamenteEvento(evento: EventoSchedulerReserva, duracionMaxMinutosRuta: number = 0): boolean {
        let esCompatible = true;

        esCompatible = evento.esCompatibleConRuta(this) && !this.existeEventoSolapadoEnHorario(evento);

        if (esCompatible && duracionMaxMinutosRuta > 0 && this.getEventosReservas().length > 0) {
            let primerEventoRuta = this.getPrimerEventoReserva();
            let minutosDuracionRuta = primerEventoRuta.start_date.getTime() < evento.start_date.getTime() ?
                DateUtils.getMinutosEntreFechas(primerEventoRuta.start_date, evento.end_date) :
                DateUtils.getMinutosEntreFechas(evento.start_date, primerEventoRuta.end_date);

            esCompatible = minutosDuracionRuta <= duracionMaxMinutosRuta;
        }

        return esCompatible;
    }

    public esCompatibleConServicio(servicio: IServicio): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();
        let conductorRuta = this.getConductor();

        if (this.opcionesAsignacionRuta.fechaHoraMin > servicio.dateFechaInicio || this.opcionesAsignacionRuta.fechaHoraMax < servicio.dateFechaFinConMargen)
            esCompatible = false;
        else if (areaTrabajoRuta && servicio.areaTrabajo && areaTrabajoRuta.id !== servicio.areaTrabajo.id)
            esCompatible = false;
        else if (servicio.tipoServicio && conductorRuta && conductorRuta.permisoConduccion.nivel < servicio.tipoServicio.permisoConduccion.nivel)
            esCompatible = false;

        return esCompatible;
    }

    public esCompatibleConAgrupacion(agrupacion: IAgrupacionCompartidos): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();
        let conductorRuta = this.getConductor();

        if (this.opcionesAsignacionRuta.fechaHoraMin > agrupacion.dateFechaInicio || this.opcionesAsignacionRuta.fechaHoraMax < agrupacion.dateFechaFinConMargen)
            esCompatible = false;
        if (areaTrabajoRuta && agrupacion.areaTrabajo && areaTrabajoRuta.id !== agrupacion.areaTrabajo.id)
            esCompatible = false;
        else if (agrupacion.tipoServicio && conductorRuta && conductorRuta.permisoConduccion.nivel < agrupacion.tipoServicio.permisoConduccion.nivel)
            esCompatible = false;

        return esCompatible;
    }

    public getPrimerEventoReserva(): EventoSchedulerReserva | null {
        return this.getEventosReservas()
            .reduce((eventoPrevio, eventoActual) =>
                eventoActual.start_date.getTime() < eventoPrevio.start_date.getTime() ?
                    eventoActual :
                    eventoPrevio
            );
    }

    public getUltimoEventoReserva(): EventoSchedulerReserva | null {
        return this.getEventosReservas()
            .reduce((eventoPrevio, eventoActual) =>
                eventoActual.end_date.getTime() > eventoPrevio.end_date.getTime() ?
                    eventoActual :
                    eventoPrevio
            );
    }

    public esCompatibleConVehiculo(vehiculo: IVehiculo): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();


        if (areaTrabajoRuta && vehiculo.areaTrabajo && areaTrabajoRuta.id !== vehiculo.areaTrabajo.id)
            esCompatible = false;
        else if (!this.getEventosReservas().every(((evento) => evento.esCompatibleConVehiculo(vehiculo))))
            esCompatible = false;

        return esCompatible;
    }

    public esCompatibleConConductor(conductor: IConductor): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();

        if (areaTrabajoRuta && conductor.areaTrabajo && areaTrabajoRuta.id !== conductor.areaTrabajo.id)
            esCompatible = false;
        else if (!this.getEventosReservas().every((evento) => evento.esCompatibleConConductor(conductor)))
            esCompatible = false;

        return esCompatible;
    }

    public bloquear() {
        this.opcionesAsignacionRuta.bloqueada = true;
    };

    public desbloquear() {
        this.opcionesAsignacionRuta.bloqueada = false;
    };

    public getVehiculoAsignadoATodosLosEventos(): IVehiculo | null {
        let todosTienenMismovehiculoAsignado = true;
        let vehiculoAsignadoCompartido = null;
        let eventosReservas = this.getEventosReservas();

        if(eventosReservas.length > 0) {
            vehiculoAsignadoCompartido = eventosReservas[0].getVehiculo();

            if(vehiculoAsignadoCompartido != null) {
                eventosReservas.forEach(evento => {
                    let vehiculoEvento = evento.getVehiculo();
                    
                    if(vehiculoEvento == null || vehiculoEvento.id != vehiculoAsignadoCompartido.id)
                        todosTienenMismovehiculoAsignado = false;
                });
            }
        }

        if(!todosTienenMismovehiculoAsignado)
            vehiculoAsignadoCompartido = null;

        return vehiculoAsignadoCompartido;
    }
}
