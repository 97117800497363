import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CustomModalComponent } from '../custom-modal/custom-modal.component';

@Component({
  selector: 'evtc-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent<T> {

  @Input()
  public label: string = '';
  @Input()
  public help: string = '';
  @Input()
  public placeHolder: string = 'N/A';
  @Input()
  public items: T[] = [];
  @Input()
  public multiple: boolean = false;
  @Input()
  public selectedItemOrItems: T|T[] = null;
  @Input()
  public formatValue: (item: T) => string = (item) => 'label' in item?item['label']: item.toString();
  @Input()
  public formatOption: (item: T) => string = (item) => this.formatValue(item);
  @Input()
  public filterOption: (search: string, item: T) => boolean = (search, item) => this.formatOption(item).toUpperCase().indexOf(search.toUpperCase()) > -1;
  @Output()
  public onSelectionChanged = new EventEmitter<any>();

  @Output() selectedItemOrItemsChange = new EventEmitter<T|T[]>();

  constructor() {
  }
  
  public ngModelChange() {
    this.selectedItemOrItemsChange.emit(this.selectedItemOrItems);
    this.onSelectionChanged.emit();
  }
}
