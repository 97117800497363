
export type OpcionesEventoScheduler = {
    modificado: boolean;
};

export type Coordenada = {
    latitud: number;
    longitud: number;
};

export enum TipoDataEventoScheduler {
    SERVICIO,
    AGRUPACION,
    MARCADOR
};

export interface IEventoScheduler {

    id: string;
    start_date: Date;
    end_date: Date;
    text: string;
    section_id: string;
    data: any;
    color: string;
    opciones: OpcionesEventoScheduler;

    readonly TIPO_DATA: TipoDataEventoScheduler;

    sincronizar();

    clone();

    getTemplateTooltip(): string;

    getTemplateBar(fechaLimiteSchedulerInicial: Date, fechaLimiteSchedulerFinal: Date): string;

    getSegundosInicialesFueraDeRango(fechaMinima: Date): number;

    getSegundosFinalesFueraDeRango(fechaMaxima: Date): number;

    getOpcionesMenuContextual(): { label: string, value: string }[];

    estaSolapadoEnHorario(evento: IEventoScheduler): boolean;
}
