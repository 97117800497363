<form class="form-servicio row" *ngIf="ruta">

    <evtc-custom-datepicker class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" label="Fecha inicio" [class.noEditable] = "isRutaBloqueada"
        [(selectedDate)]="fechaInicioFormulario" (onSelectionChanged)="validarFormulario()"
        help="Indique en este campo la fecha de inicio de la ruta. No se podrán asignar servicios anteriores a esta fecha."></evtc-custom-datepicker>
    <evtc-custom-timepicker class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" label="Hora inicio" [class.noEditable] = "isRutaBloqueada"
        [(selectedTime)]="horaInicioFormulario" (onSelectionChanged)="validarFormulario()"
        help="Indique en este campo la hora de inicio de la ruta. No se podrán asignar servicios anteriores a esta hora."></evtc-custom-timepicker>

    <evtc-custom-datepicker class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" label="Fecha Fin" [class.noEditable] = "isRutaBloqueada"
        [(selectedDate)]="fechaFinFormulario" (onSelectionChanged)="validarFormulario()"
        help="Indique en este campo la fecha de fin de la ruta. No se podrán asignar servicios cuyo fin sea posterior a esta fecha."></evtc-custom-datepicker>
    <evtc-custom-timepicker class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" label="Hora fin" [class.noEditable] = "isRutaBloqueada"
        [(selectedTime)]="horaFinFormulario" (onSelectionChanged)="validarFormulario()"
        help="Indique en este campo la hora de fin de la ruta. No se podrán asignar servicios cuyo fin sea posterior a esta hora."></evtc-custom-timepicker>

        <!--
    <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label for="selectAccionEventosIncompatibles"
            style="width: 100%;
                text-align: left;"
        >Acción para eventos incompatibles</label>
        <select name="accionEventosIncompatibles"[(ngModel)]="accionEventosIncompatiblesFormulario" class="form-select formField" aria-label="No seleccionado" id="selectAccionEventosIncompatibles"
            style="width: 100%;"
        >
            <option value="1">Desplazar a sección "Sin Asignar"</option>
            <option value="2">Redistribuir automáticamente</option>
        </select>
    </div>
    -->

    <evtc-custom-select class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" label="Conductor asignado"
        [items]="conductoresCompatiblesConRuta" [(selectedItemOrItems)]="conducrorAsignadoFormulario" placeHolder="SIN ASIGNAR"
        [formatValue]="ConductorBll.formatToSelectValue" (onSelectionChanged)="checkConductorAsignado()"
        help="Seleccione en este campo el conductor que se asignará a todos los servicios que estén asignados a esta ruta."></evtc-custom-select>

    <div class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" [class.noEditable] = "conducrorAsignadoFormulario == null"
        style="
            display: flex;
            align-items: center;"
    >
    <input
        mdbCheckbox
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="asignacionConductorConfirmadaFormulario"
        name="asignacionConductorConfirmadaCheckbox"
        id="asignacionConductorConfirmadaCheckbox"
        style="margin: 0 5px 0 0; position: static;"
    />
    <label class="form-check-label" for="asignacionConductorConfirmadaCheckbox">
        Confirmación de asignación del conductor
    </label>
    </div>

    <evtc-custom-select class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" label="Vehiculo asignado"
        [items]="vehiculosCompatiblesConRuta" [(selectedItemOrItems)]="vehiculoAsignadoFormulario"
        placeHolder="SIN ASIGNAR" [formatValue]="VehiculoBll.formatToSelectValue"
        help="Seleccione en este campo el vehículo que se asignará a todos los servicios que estén asignados a esta ruta. Después podrá cambiar el vehículo a los servicios de forma independiente. En el caso de dejar vacío este campo los servicios se quedarán con el vehículo que ya tengan asignado."></evtc-custom-select>
    
    <div *ngIf="erroresEnFormulario.length > 0" class="errores-formulario-container form-group col-12">
        <ul *ngFor="let error of erroresEnFormulario">
            <li>{{error}}.</li>
        </ul>
    </div>

    <div *ngIf="avisosEnFormulario.length > 0" class="avisos-formulario-container form-group col-12">
        <ul *ngFor="let aviso of avisosEnFormulario">
            <li>{{aviso}}.</li>
        </ul>
    </div>
</form>