import { RutaEventos } from "../../business-logic-layer/asignacion-automatica-servicios/ruta-servicios.class";
import { ServicioBll } from "../../business-logic-layer/servicio.bll";
import { IAreaTrabajo } from "../../entity/area-trabajo.interface";
import { IConductor } from "../../entity/conductor.interface";
import { IServicio } from "../../entity/servicio.interface";
import { ITipoServicio } from "../../entity/tipo-servicio.interface";
import { IVehiculo } from "../../entity/vehiculo.interface";
import { DateUtils } from "../../utils/date.util";
import { UserInterfaceUtils } from "../../utils/user-interface.util";
import { EventoSchedulerReserva } from "./evento-scheduler-reserva.class";
import { OpcionesEventoScheduler, TipoDataEventoScheduler } from "./evento-scheduler.interface";

export class EventoSchedulerServicio extends EventoSchedulerReserva {
    data: IServicio;

    constructor(
        data: IServicio,
        section_id: string,
        text: string,
        opciones: OpcionesEventoScheduler = { modificado: false },
        estado?: number
    ) {
        super(
            'evento_servicio_' + data.id,
            data,
            TipoDataEventoScheduler.SERVICIO,
            section_id,
            text,
            opciones,
            estado
        );
    }

    public sincronizar() {
        this.start_date = this.data.dateFechaInicio;
        this.end_date = this.data.dateFechaFinConMargen;
    }

    clone() {
        return new EventoSchedulerServicio(
            this.data,
            this.section_id,
            this.text,
            this.opciones,
            this.estado
        );
    }

    getTemplateTooltip(): string {
        let servicio: IServicio = this.data;
        let tipo = ServicioBll.getNombreTipo(servicio);

        let texto = "<div class='tooltipSchedulerContainer'><table class='tooltipSchedulerTable'>";
        texto += "<tr><th colspan='3'>SERVICIO " + servicio.id + " (" + tipo + ")</th></tr>";
        let iconosString = ServicioBll.getIconosDatosServicio(servicio).join('');
        if (this.opciones.modificado)
            iconosString = "<i class='fas fa-pen icono-dato-servicio'></i>" + iconosString;

        texto += "<tr><th colspan='3'>" + iconosString + "</th></tr>";
        texto += "<tr><th>A.T.</th><td colspan='2'>" + (servicio.areaTrabajo ? servicio.areaTrabajo.nombre : '-') + "</td></tr>";

        texto += "<tr><th>T.S.</th>";
        texto += servicio.tipoServicio ?
            "<td>" + servicio.tipoServicio.nombre + " [" + servicio.tipoServicio.permisoConduccion.nombre + "] <td style='background-color: " + servicio.tipoServicio.colorHex + ";'></td></td>" :
            "<td colspan='2'>-</td>";
        texto += "</tr>";

        let extraDireccionOrigen = "";
        let extraDireccionDestino = "";

        let extraDireccion = "[Nº Vuelo " + (servicio.numVuelo ? servicio.numVuelo : '-') + "]";
        if (servicio.esSalida === ServicioBll.TIPO_ES_LLEGADA)
            extraDireccionOrigen += extraDireccion;
        else if (servicio.esSalida === ServicioBll.TIPO_ES_SALIDA)
            extraDireccionDestino += extraDireccion;

        let duracionSegundosConMargen = (servicio.dateFechaFinConMargen.getTime() - servicio.dateFechaInicio.getTime()) / 1000;

        texto += "<tr><th>ORI.</th><td>" + DateUtils.parseDateToString(servicio.dateFechaInicio) + "</td><td>" + servicio.origen + " " + extraDireccionOrigen + "</td></tr>";
        texto += "<tr><th>DES.</th><td>" + DateUtils.parseDateToString(servicio.dateFechaFin) + "</td><td>" + servicio.destino + " " + extraDireccionDestino + "</td></tr>";
        texto += "<tr><th>DUR.</th><td>" + DateUtils.formatearDuracionSegundos(servicio.duracionSegundos) + "</td><td>" + DateUtils.formatearDuracionSegundos(duracionSegundosConMargen) + " (Margen Incl.)</td></tr>";
        texto += "<tr><th>PAX.</th><td colspan='2'>" + servicio.numeroAdultos + " adultos, " + servicio.numeroNinyos + " niños, " + servicio.numeroBebes + " bebés</td></tr>";

        let textoVehiculo = servicio.vehiculo ? servicio.vehiculo.alias + " [" + servicio.vehiculo.matricula + "]" : "-";
        texto += "<tr><th>VEH.</th><td colspan='2'>" + textoVehiculo + "</td></tr>";

        let nombreViajero = '-';
        if (servicio.viajero) {
            nombreViajero = servicio.viajero.nombre;

            if (servicio.viajero.apellido1)
                nombreViajero += servicio.viajero.apellido1;
            if (servicio.viajero.apellido2)
                nombreViajero += servicio.viajero.apellido2;
        }

        /* if (servicio.esAgrupacionCompartidos) {
            texto += "<tr><th>SER.</th><td colspan='2'>(" + servicio.serviciosAgrupacionCompartidos.map(servicio => servicio.id).join(') (') + ")</td></tr>";
        } */
        else {
            texto += "<tr><th>CLI.</th><td colspan='2'>" + (servicio.cliente ? servicio.cliente.nombre : '-') + "</td></tr>";
            texto += "<tr><th>VIA.</th><td colspan='2'>" + nombreViajero + "</td></tr>";
            texto += "<tr><th>REF.</th><td colspan='2'>" + (servicio.referencia ? servicio.referencia : '-') + "</td></tr>";
        }

        texto += "<tr><td colspan='3'>" + (servicio.descripcion ? servicio.descripcion : '') + "</td></tr>";
        texto += "</table></div>";

        return texto;
    }

    getTemplateBar(fechaLimiteSchedulerInicial: Date, fechaLimiteSchedulerFinal: Date): string {
        let servicio = this.data;
        let segundosInicialesFueraDeRango = this.getSegundosInicialesFueraDeRango(fechaLimiteSchedulerInicial);
        let segundosFinalesFueraDeRango = this.getSegundosFinalesFueraDeRango(fechaLimiteSchedulerFinal);

        let prefijoTipo = ServicioBll.getPrefijoTipo(servicio);
        let iconoLLegadaSalida = '';

        if (servicio.esSalida === ServicioBll.TIPO_ES_LLEGADA)
            iconoLLegadaSalida = "<i class='fas fa-plane-arrival icono-dato-servicio'></i>";
        else if (servicio.esSalida === ServicioBll.TIPO_ES_SALIDA)
            iconoLLegadaSalida = "<i class='fas fa-plane-departure icono-dato-servicio'></i>";

        /* let iconoAgrupacionCompartidos = servicio.esAgrupacionCompartidos ?
            "<i class='fas fa-object-group icono-dato-servicio'></i>" :
            ''; */

        let colorFondoClaro = servicio.tipoServicio ? UserInterfaceUtils.esColorHexFondoClaro(servicio.tipoServicio.colorHex) : false;

        let plantilla = "<div class='event_bar_content' style='color: #" + (colorFondoClaro ? '000000' : 'FFFFFF') + ";'>";
        plantilla += iconoLLegadaSalida;
        plantilla += "<span class='prefijo_tipo'>" + prefijoTipo + "</span>";
        /* plantilla += iconoAgrupacionCompartidos; */
        plantilla += "<span>" + servicio.id + "</span>";

        // ICONOS
        plantilla += ServicioBll.getIconosDatosServicio(servicio, false).join('');

        plantilla += "</div>";

        // MARGENES
        let duracionSegundosConMargenVisible = DateUtils.getSegundosEntreFechas(servicio.dateFechaInicio, servicio.dateFechaFinConMargen) - segundosInicialesFueraDeRango - segundosFinalesFueraDeRango;
        let margenSegundosInicialesVisible = (servicio.margenSegundosInicial) - segundosInicialesFueraDeRango;
        let margenSegundosFinalesVisible = (servicio.margenSegundosFinal) - segundosFinalesFueraDeRango;

        if (margenSegundosInicialesVisible > 0) {
            let porcentaje = (margenSegundosInicialesVisible / duracionSegundosConMargenVisible) * 100;
            plantilla += "<div class='event_bar_margen_tiempo inicial' style='width:" + porcentaje + "%'></div>";
        }
        if (margenSegundosFinalesVisible > 0) {
            let porcentaje = (margenSegundosFinalesVisible / duracionSegundosConMargenVisible) * 100;
            plantilla += "<div class='event_bar_margen_tiempo final' style='width:" + porcentaje + "%'></div>";
        }

        return plantilla;
    }

    public getCambios(eventoSinAlterar: EventoSchedulerServicio): { id: number, mensajesCambios: string[] } {
        let mensajeCambios = ServicioBll.getMensajesCambios(eventoSinAlterar.data, this.data);
        return {
            id: this.data.id,
            mensajesCambios: mensajeCambios
        }
    }

    public asignarIdsConductores(idConductores: number[]) {
        this.data.idConductores = idConductores;
    }

    public asignarVehiculo(vehiculo: IVehiculo) {
        this.data.vehiculo = vehiculo;
    }

    public getOpcionesMenuContextual() {
        let servicio = this.data;

        const tieneVehiculoAsignado = servicio.vehiculo != null;

        let contextMenuItems = [] as { label: string, value: string }[];
        contextMenuItems.push({
            label: (tieneVehiculoAsignado ? 'Reasignar' : 'Asignar') + ' vehículo',
            value: EventoSchedulerReserva.CONTEXT_MENU_OPCION_ASIGNAR_VEHICULO
        });

        if (tieneVehiculoAsignado)
            contextMenuItems.push({
                label: 'Eliminar vehículo',
                value: EventoSchedulerReserva.CONTEXT_MENU_OPCION_ELIMINAR_VEHICULO
            });

        contextMenuItems.push({
            label: 'Editar servicio',
            value: EventoSchedulerReserva.CONTEXT_MENU_OPCION_EDITAR
        });

        return contextMenuItems;
    }

    public esCompatibleConVehiculo(vehiculo: IVehiculo): boolean {
        return ServicioBll.esCompatibleConVehiculo(this.data, vehiculo);
    }

    public esCompatibleConConductor(conductor: IConductor): boolean {
        return ServicioBll.esCompatibleConConductor(this.data, conductor);
    }

    esCompatibleConRuta(ruta: RutaEventos): boolean {
        return ruta.esCompatibleConServicio(this.data);
    }

    public getIdConductores(): number[] {
        return this.data.idConductores;
    }

    public getVehiculo(): IVehiculo | null {
        return this.data.vehiculo;
    }

    public getTipoServicio(): ITipoServicio | null {
        return this.data.tipoServicio
    }

    public getAreaTrabajo(): IAreaTrabajo | null {
        return this.data.areaTrabajo
    }

    public getDataId(): number {
        return this.data.id
    }

    public getCoordenadasOrigen(): { latitud: number; longitud: number; } {
        return {
            latitud: this.data.latitudOrigen,
            longitud: this.data.longitudOrigen
        }
    }

    public getCoordenadasDestino(): { latitud: number; longitud: number; } {
        return {
            latitud: this.data.latitudDestino,
            longitud: this.data.longitudDestino
        }
    }
}
