import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RutaEventos } from 'src/app/core/models/business-logic-layer/asignacion-automatica-servicios/ruta-servicios.class';
import { ConductorBll } from 'src/app/core/models/business-logic-layer/conductor.bll';
import { VehiculoBll } from 'src/app/core/models/business-logic-layer/vehiculo.bll';
import { IConductor } from 'src/app/core/models/entity/conductor.interface';
import { IVehiculo } from 'src/app/core/models/entity/vehiculo.interface';

@Component({
  selector: 'evtc-form-configuracion-ruta',
  templateUrl: './form-configuracion-ruta.component.html',
  styleUrls: ['./form-configuracion-ruta.component.scss']
})
export class FormConfiguracionRutaComponent implements OnInit {

  @Input()
  public ruta: RutaEventos;
  @Output()
  public rutaChange = new EventEmitter<RutaEventos>();

  public ConductorBll = ConductorBll;
  public VehiculoBll = VehiculoBll;
  
  public fechaInicioFormulario: Date;
  public horaInicioFormulario: Date;
  public fechaFinFormulario: Date;
  public horaFinFormulario: Date;
  public accionEventosIncompatiblesFormulario: number;

  public conductoresCompatiblesConRuta: IConductor[];
  public conducrorAsignadoFormulario: IConductor;
  public asignacionConductorConfirmadaFormulario: boolean;

  public vehiculosCompatiblesConRuta: IVehiculo[];
  public vehiculoAsignadoFormulario: IVehiculo;

  public isRutaBloqueada: boolean;

  public erroresEnFormulario: string[];
  public avisosEnFormulario: string[];

  constructor() {
    this.erroresEnFormulario = [];
    this.avisosEnFormulario = [];
    this.conductoresCompatiblesConRuta = [];
    this.vehiculosCompatiblesConRuta = [];
  }

  ngOnInit(): void {
    this.accionEventosIncompatiblesFormulario = 2;

    if(this.ruta) {
      this.fechaInicioFormulario = this.horaInicioFormulario = this.ruta.getOpcionesAsignacionRuta().fechaHoraMin;
      this.fechaFinFormulario = this.horaFinFormulario = this.ruta.getOpcionesAsignacionRuta().fechaHoraMax;
      this.conducrorAsignadoFormulario = this.ruta.getConductor();
      this.asignacionConductorConfirmadaFormulario = this.ruta.getConfirmacionAsignacion();
      this.vehiculoAsignadoFormulario = null; // TODO
      this.isRutaBloqueada = this.ruta.getOpcionesAsignacionRuta().bloqueada;
      this.vehiculoAsignadoFormulario = this.ruta.getVehiculoAsignadoATodosLosEventos()
    }
    else {
      this.fechaInicioFormulario = this.horaInicioFormulario = this.fechaFinFormulario = this.horaFinFormulario = new Date();
      this.conducrorAsignadoFormulario = null;
      this.asignacionConductorConfirmadaFormulario = false;
      this.vehiculoAsignadoFormulario = null;
      this.isRutaBloqueada = false;
    }
  }

  public validarFormulario() {
    this.erroresEnFormulario = [];
    this.avisosEnFormulario = [];

    this.validarInicio();
    this.setConfirmButtonDisabled(this.erroresEnFormulario.length > 0);

    this.emitirCambios();
  }

  private validarInicio() {
    if (!this.horaInicioFormulario || !this.fechaInicioFormulario) {
      this.erroresEnFormulario.push('Debes introducir una fecha y hora inicio');
    }
    else if (!this.horaFinFormulario || !this.fechaFinFormulario) {
      this.erroresEnFormulario.push('Debes introducir una fecha y hora fin');
    }

    if (this.erroresEnFormulario.length === 0) {
      let fechaHoraInicioNew = this.getFechaHoraInicioFormulario();
      let fechaHoraFinNew = this.getFechaHoraFinFormulario();

      if((fechaHoraInicioNew > fechaHoraFinNew)) {
        this.avisosEnFormulario.push('La fecha y hora Origen es posterior a la fecha y hora fin');
      }
    }
  }

  public checkConductorAsignado() {
    if(this.conducrorAsignadoFormulario == null)
      this.asignacionConductorConfirmadaFormulario = false;
  }

  public getFechaHoraInicioFormulario() {
    return new Date(
      this.fechaInicioFormulario.getFullYear(),
      this.fechaInicioFormulario.getMonth(),
      this.fechaInicioFormulario.getDate(),
      this.horaInicioFormulario.getHours(),
      this.horaInicioFormulario.getMinutes()
    );
  }

  public getFechaHoraFinFormulario() {
    return new Date(
      this.fechaFinFormulario.getFullYear(),
      this.fechaFinFormulario.getMonth(),
      this.fechaFinFormulario.getDate(),
      this.horaFinFormulario.getHours(),
      this.horaFinFormulario.getMinutes()
    );
  }

  public emitirCambios() {
    this.rutaChange.emit(this.ruta);
  }

  // TODO ACCEDER AL BOTON DESDE SWEET-ALERT-2
  private setConfirmButtonDisabled(disabled: boolean) {
    let botonConfirmar = document.getElementsByClassName('swal2-confirm')[0] as HTMLElement;
    //botonConfirmar.style.display = disabled? 'none': 'inline-block';
    disabled?
      botonConfirmar.setAttribute('disabled', 'true'):
      botonConfirmar.removeAttribute('disabled');
  }

}
