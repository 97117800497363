import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from "@angular/core";

// Entity
import { IAreaTrabajo } from "src/app/core/models/entity/area-trabajo.interface";
import { IConductor } from "src/app/core/models/entity/conductor.interface";
import { EventoSchedulerReserva } from "src/app/core/models/helper/dhtmlxHelper/evento-scheduler-reserva.class";
import { DhtlmxSchedulerHelper } from "src/app/core/models/helper/dhtmlxHelper/dhtmlx-scheduler.helper";
import { IVehiculo } from "src/app/core/models/entity/vehiculo.interface";
import { ITipoServicio } from "src/app/core/models/entity/tipo-servicio.interface";
import { IServicio } from "../../../core/models/entity/servicio.interface";

// Service
import { AsignacionService } from '../services/asignacion.service';

//Aux Modules
import { SwalService } from '../../../shared/services/swal.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EstadoVistaAsig } from "src/app/core/models/estado-vista-asig.class";

import { AuthService } from "../../auth/services/auth.service";

import { IPermisoConduccion } from "src/app/core/models/entity/permiso-conduccion.interface";
import { ServicioBll } from "src/app/core/models/business-logic-layer/servicio.bll";
import { IConfiguracion } from "src/app/core/models/entity/configuracion.interface";
import { IEstadoServicio } from "src/app/core/models/entity/estado-servicio.interface";
import { EstadoServicioBll } from "src/app/core/models/business-logic-layer/estado-servicio.bll";
import { AreaTrabajoBll } from "src/app/core/models/business-logic-layer/area-trabajo.bll";
import { TipoServicioBll } from "src/app/core/models/business-logic-layer/tipo-servicio.bll";
import { ConductorBll } from "src/app/core/models/business-logic-layer/conductor.bll";
import { VehiculoBll } from "src/app/core/models/business-logic-layer/vehiculo.bll";
import { DateUtils } from "src/app/core/models/utils/date.util";
import { ContextMenuComponent } from "src/app/shared/components/context-menu/context-menu.component";
import { JsonUtils } from "src/app/core/models/utils/json.util";
import { AgrupacionCompartidosBll } from "src/app/core/models/business-logic-layer/agrupacion-compartidos.bll";
import { IAgrupacionCompartidos } from "src/app/core/models/entity/agrupacion-compartidos.interface";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'evtc-asignacion-conductores',
  templateUrl: './asignacion-conductores.component.html',
  styleUrls: ['./asignacion-conductores.component.scss']
})
export class AsignacionConductoresComponent implements OnInit, AfterViewInit {

  @ViewChild('containerRoot', { read: ViewContainerRef }) containerRoot: ViewContainerRef;
  @ViewChild("contextMenuScheduler") public contextMenuScheduler: ContextMenuComponent;

  readonly DateUtils = DateUtils;
  readonly schedulerHelper: DhtlmxSchedulerHelper;
  private puedeActualizarScheduler: boolean = true;

  // Claves LocalStorage
  private readonly KEY_LOCALSTORAGE_ESTADOS = 'estadosVistaScheduler_manual';
  private readonly KEY_VERSION_AGRUPACIONES = 'agrupaciones_activas_manual';

  // Movimiento Drag & Drop
  private readonly BLOCKED_MOVEMENT = 0;
  private readonly FREE_MOVEMENT = 1;
  private readonly HORIZONTAL_MOVEMENT = 2;
  private readonly VERTICAL_MOVEMENT = 3;

  private currentMovement = this.BLOCKED_MOVEMENT;
  private eventBeforeDrag: EventoSchedulerReserva;

  // Zoom
  private readonly VALOR_SUMA_X_STEP = 5;

  // Datos BD API (Filtro)
  private readonly COD_RESERVADO = 'RESE';
  private readonly COD_ASIGNADO = 'ASIG';
  private readonly COD_JORNADA_ACTIVADA = 'JORACT';
  private readonly COD_JORNADA_DESACTIVADA = 'JORDESACT';

  datosAPI = {
    estados: [
      { nombre: 'RESERVADOS', codigo: this.COD_RESERVADO },
      { nombre: 'ASIGNADOS', codigo: this.COD_ASIGNADO }
    ] as IEstadoServicio[],
    conductores: null as IConductor[],
    vehiculos: null as IVehiculo[],
    areasTrabajo: null as IAreaTrabajo[],
    tiposServicio: null as ITipoServicio[],
    permisosConduccion: null as IPermisoConduccion[],
    configuracion: null as IConfiguracion,
    relacionesConAeropuerto: [
      { label: 'LLEGADA', value: ServicioBll.TIPO_ES_LLEGADA },
      { label: 'SALIDA', value: ServicioBll.TIPO_ES_SALIDA },
      { label: 'NINGUNA', value: ServicioBll.TIPO_NO_SALIDA_LLEGADA }
    ],
    jornadasConductores: [
      { label: 'ACTIVADA', value: this.COD_JORNADA_ACTIVADA },
      { label: 'DESACTIVADA', value: this.COD_JORNADA_DESACTIVADA }
    ]
  };

  filtroVisible: boolean = false;
  erroresEnFiltro: string[] = [];
  avisosEnFiltro: string[] = [];

  // Control de estados de la vista
  readonly NUM_MAX_RANGO_HORAS_FILTRO = 24;
  readonly NUM_MAX_ESTADOS = 20;
  estadoVistaSinAlterar: Readonly<EstadoVistaAsig>;
  estadoVistaActual: EstadoVistaAsig;
  estadosVistaScheduler: EstadoVistaAsig[] = [];

  cargandoDatos: boolean = false;
  mensajesCargasDatosEnCurso: { id: number, mensaje: string }[] = [];
  mensajeCargaDatosMostrar: string;

  public EstadoServicioBll = EstadoServicioBll;
  public AreaTrabajoBll = AreaTrabajoBll;
  public TipoServicioBll = TipoServicioBll;
  public ConductorBll = ConductorBll;
  public VehiculoBll = VehiculoBll;

  // Modal Guardar Estado Vista
  modalRefModalGuardarEstadoVista?: BsModalRef;
  tituloEstadoModalGuardar: string;

  // Modal Opciones Estados Vista
  modalRefModalEstadosVista?: BsModalRef;
  estadoVistaSelModal: EstadoVistaAsig;

  // Modal Restablecer Estados Vista
  modalRefModalRecuperarEstadoVista?: BsModalRef;

  // Elementos DOM
  // Botones opciones scheduler
  @ViewChild('desplazamiento_timeline_container') botonDesplazamiento: ElementRef<HTMLDivElement>;
  @ViewChild('boton_mas_zoom') botonMasZoom: ElementRef<HTMLDivElement>;
  @ViewChild('boton_menos_zoom') botonMenosZoom: ElementRef<HTMLDivElement>;

  // Templates
  @ViewChild('template_recuperar_estado_vista') templateRecuperarEstadoVista: TemplateRef<HTMLElement>;
  @ViewChild('template_guardar_estado_vista') templateGuardarEstadoVista: TemplateRef<HTMLElement>;
  @ViewChild('template_selector_estado_vista') templateSelectorEstadoVista: TemplateRef<HTMLElement>;

  constructor(
    private asignacionService: AsignacionService,
    private swalService: SwalService,
    private modalService: BsModalService,
    private authService: AuthService
  ) {
    this.schedulerHelper = new DhtlmxSchedulerHelper('scheduler_container');

    this.leerEstadosVistaLocalStorage();
    let estadosRecuperados = this.leerEstadosVistaLocalStorage();

    if (estadosRecuperados)
      this.setEstadosVista(estadosRecuperados);
    else
      this.initEstadoVista();
  }

  ngOnInit(): void {
    this.getDatosFromAPI(false).then((sinErrores) => {
      if (sinErrores)
        this.initVista();
    });
  }

  ngAfterViewInit(): void {
  }

  private initVista() {
    let estadosVistaRecuperados = this.estadosVistaScheduler.length > 0;

    this.initSheduler();

    if (estadosVistaRecuperados) {
      // TODO CAMBIAR SWAL_SERVICE
      this.modalRefModalRecuperarEstadoVista = this.openModal(this.templateRecuperarEstadoVista, 'modal-recuperar-estado', true);
    }
    else {
      this.getDatosFromAPI(true);
    }
  }

  private initEstadoVista() {
    let estadoVistaInicial = new EstadoVistaAsig([], [], [], 'INICIAL');

    // Obtenemos el rango de fechas inicial
    let ahora = new Date();
    let minutosAhora = ahora.getMinutes();
    // Redondeamos los minutos hacia el actual o proximo múltiplo de 5
    let newMinutosAhora = (minutosAhora - minutosAhora % 5) + 5;
    ahora.setMinutes(newMinutosAhora);
    ahora.setSeconds(0, 0);

    let despues = new Date(ahora);
    // Dejamos un rango inicial de 12 Horas
    despues.setHours(despues.getHours() + 12);

    let valoresFiltro = estadoVistaInicial.datosFiltro;
    valoresFiltro.horaInicio = valoresFiltro.fechaInicio = valoresFiltro.fechaHoraInicio = new Date(ahora);
    valoresFiltro.horaFin = valoresFiltro.fechaFin = valoresFiltro.fechaHoraFin = new Date(despues);

    this.setEstadosVista([estadoVistaInicial]);
  }

  private initSheduler() {
    let valoresFiltro = this.estadoVistaActual.datosFiltro;

    this.schedulerHelper.setOnBeforeEventChanged((eventNow: EventoSchedulerReserva, ev: Event, is_new: boolean, eventOld: EventoSchedulerReserva) => this.triggerOnBeforeEventChanged(eventNow, ev, is_new, eventOld));
    this.schedulerHelper.setOnBeforeLightbox((id: string) => false);
    this.schedulerHelper.setOnBeforeDrag((id: string, mode: string, ev: MouseEvent) => this.triggerOnBeforeDrag(id, mode, ev));
    this.schedulerHelper.setOnEventDrag((id: string, mode: string, ev: MouseEvent) => this.triggerOnEventDrag(id, mode, ev));
    this.schedulerHelper.setOnDragEnd((id: string, mode: string, ev: MouseEvent) => this.triggerOnDragEnd(id, mode, ev));
    this.schedulerHelper.setTimeLineScaleLabel((key: string, label: string, section) => this.triggerTimeLineScaleLabel(key, label, section));
    this.schedulerHelper.setTemplateEventClass((start: Date, end: Date, event: EventoSchedulerReserva) => this.setTemplateEventClass(start, end, event));
    this.schedulerHelper.setOnContextMenu((eventId: string, event: MouseEvent) => this.setOnContextMenu(eventId, event));
    this.schedulerHelper.setOnBeforeTooltip((eventId: string) => !this.contextMenuScheduler.seEstaMostrando); // TODO CAMBIAR

    this.schedulerHelper.iniciarActualizarScheduler(
      valoresFiltro.fechaHoraInicio,
      valoresFiltro.fechaHoraFin,
      [],
      [],
      []
    );
  }

  private triggerOnBeforeEventChanged(eventNow: EventoSchedulerReserva, ev: Event, esNuevo: boolean, eventOld: EventoSchedulerReserva): boolean {
    let realizarCambios = true;
    let eventos = this.estadoVistaActual.eventos;

    if (esNuevo) {
      let index = eventos.push(eventNow);
    }

    else {
      let index = eventos.findIndex((evento) => evento.id === eventNow.id);

      if (index === -1)
        realizarCambios = false;
      else
        eventos[index] = eventNow;
    }

    return realizarCambios;
  }

  private triggerOnBeforeDrag(id: string, mode: string, ev: MouseEvent): boolean {
    let event = this.schedulerHelper.getEvent(id);
    let esEventoReserva = event instanceof EventoSchedulerReserva;

    // Guardamos el estado inicial del evento
    this.eventBeforeDrag = event.clone();

    let seccionId = event.section_id;
    let movimientoPermitido = esEventoReserva && this.currentMovement != this.BLOCKED_MOVEMENT;

    if (movimientoPermitido) {
      if (this.currentMovement === this.FREE_MOVEMENT ||
        this.currentMovement === this.VERTICAL_MOVEMENT) {
        // Marcamos las secciones compatibles
        this.marcarSeccionesCompatibles(event as EventoSchedulerReserva);
        this.schedulerHelper.updateView();
      }

      return true;
    }
    else
      return false; //Drag-Drop bloqueado
  }

  private triggerOnEventDrag(id: string, mode: string, ev: MouseEvent) {
    // Obtenemos el objeto del evento
    let eventAfterDrag = this.schedulerHelper.getEvent(id) as EventoSchedulerReserva;

    if (this.schedulerHelper.getCurrentMode() === "timeline" && mode === 'move') {
      let destinoValido = this.esIdConductorValido(eventAfterDrag.section_id) ||
        eventAfterDrag.section_id === this.schedulerHelper.KEY_SIN_ASIGNAR;
      let bloquearMovHorizontal = this.currentMovement === this.VERTICAL_MOVEMENT;
      let bloquearMovVertical = this.currentMovement === this.HORIZONTAL_MOVEMENT || !destinoValido;

      if (bloquearMovVertical) {
        eventAfterDrag.section_id = this.eventBeforeDrag.section_id;
        this.schedulerHelper.updateEvent(id);
      }

      if (bloquearMovHorizontal) {
        eventAfterDrag.start_date = this.eventBeforeDrag.start_date;
        eventAfterDrag.end_date = this.eventBeforeDrag.end_date;
        this.schedulerHelper.updateEvent(id);
      }

      // En caso de haber cambiado de sección
      if (eventAfterDrag.section_id != this.eventBeforeDrag.section_id) {

        if (eventAfterDrag.section_id == this.schedulerHelper.KEY_SIN_ASIGNAR) {
          eventAfterDrag.asignarIdsConductores([]);
        }
        else if (this.esIdConductorValido(eventAfterDrag.section_id)) {
          let listaConductores = this.estadoVistaActual.conductores;
          let idConductor = parseInt(eventAfterDrag.section_id);
          let conductorAsignado = listaConductores.find((cond) => cond.id === idConductor);

          if (eventAfterDrag.esCompatibleConConductor(conductorAsignado)) {
            eventAfterDrag.asignarIdsConductores([idConductor]);
          }
          else {
            eventAfterDrag.section_id = this.eventBeforeDrag.section_id;
            this.schedulerHelper.updateEvent(id);
          }
        }
      }

      //Guardamos el estado actual del evento
      this.eventBeforeDrag = eventAfterDrag.clone();
    }
  }

  private triggerOnDragEnd(id: string, mode: string, ev: MouseEvent) {
    if (this.schedulerHelper.getCurrentMode() === "timeline" && mode === 'move') {
      if (this.currentMovement === this.FREE_MOVEMENT ||
        this.currentMovement === this.VERTICAL_MOVEMENT) {
        this.marcarSeccionesCompatibles();
        this.comprobarCambiosEnEventos();
      }
    }
  }

  private triggerTimeLineScaleLabel(key: string, label: string, section): string {
    let texto = '';

    if (this.puedeActualizarScheduler) {
      if (this.esIdConductorValido(key)) {
        let listaConductores = this.estadoVistaActual.conductores;
        let idConductor = parseInt(key);
        let conductor = listaConductores.find((c) => c.id === idConductor);

        let codigo = conductor.codigo;
        let nombreCompleto = conductor.nombre + ' ' + conductor.apellido1;
        let nombreCompacto = conductor.nombre + ' ' + conductor.apellido1.substr(0, 1) + '.';

        if (conductor.apellido2) {
          nombreCompleto += ' ' + conductor.apellido2;
          nombreCompacto += ' ' + conductor.apellido2.substr(0, 1) + '.';
        }

        texto = '<span class="scaley-label-conductor" data-toggle="tooltip" data-placement="bottom" title="' + nombreCompleto + ' [' + conductor.permisoConduccion.nombre + '] (' + (conductor.areaTrabajo ? conductor.areaTrabajo.nombre : 'SIN ÁREA TRABAJO') + ')">' +
          '<span class="scaley-codigo-conductor">' + codigo + '</span>' + nombreCompacto +
          '</span>';
      }
    }

    return texto;
  }

  private setTemplateEventClass(start: Date, end: Date, event: EventoSchedulerReserva) {
    let className = 'event-bar-container';

    if (event.opciones.modificado)
      className += ' event-modificado';
    if (this.schedulerHelper.eventoFueraDeRangoInicial(event))
      className += ' event-container-fuera-rango-inicial';
    if (this.schedulerHelper.eventoFueraDeRangoFinal(event))
      className += ' event-container-fuera-rango-final';

    return className;
  }

  private setOnContextMenu(eventId: string, event: MouseEvent) {
    let mostrarContextMenuNavegador = true;

    if (eventId) {
      mostrarContextMenuNavegador = false;

      let eventScheduler = this.schedulerHelper.getEvent(eventId);
      let contextMenuItems = eventScheduler.getOpcionesMenuContextual();

      let handleMenuItemClick = (data: { menuItemSelected: { label: string, value: string }, $event: Event }) => {
        switch (data.menuItemSelected.value) {
          case EventoSchedulerReserva.CONTEXT_MENU_OPCION_ASIGNAR_VEHICULO: this.mostrarModalAsignacionVehiculoEvento(eventScheduler as EventoSchedulerReserva); break;
          case EventoSchedulerReserva.CONTEXT_MENU_OPCION_ELIMINAR_VEHICULO: this.mostrarModalEliminarVehiculoEvento(eventScheduler as EventoSchedulerReserva); break;
          case EventoSchedulerReserva.CONTEXT_MENU_OPCION_EDITAR: this.mostrarModalEditarServicio(eventScheduler as EventoSchedulerReserva); break;
        }
      }

      this.contextMenuScheduler.setOnContextMenuClickSubscription((data) => handleMenuItemClick(data));

      // Cerramos tooltip en caso de estar mostrandose uno en pantalla
      this.schedulerHelper.closeTooltip();
      this.contextMenuScheduler.show(contextMenuItems, event);
    }

    return mostrarContextMenuNavegador;
  }

  private marcarSeccionesCompatibles(evento?: EventoSchedulerReserva) {
    let that = this;

    this.schedulerHelper.setClassScaleY((key: string, label: string, section) => {
      if (evento && that.esIdConductorValido(key)) {
        let listaConductores = that.estadoVistaActual.conductores;
        let conductorId = parseInt(key);
        let conductor = listaConductores.find(c => c.id === conductorId);

        return evento.esCompatibleConConductor(conductor) ?
          'seccion_compatible_drag' :
          'seccion_incompatible_drag';
      }

      return '';
    });
  }

  private esIdConductorValido(id: string): boolean {
    return (/^\-?\d+$/).test(id);
  }

  public aumentarZoom() {
    let limiteAlcanzado = this.schedulerHelper.sumarMinutosXStep(- this.VALOR_SUMA_X_STEP);

    this.botonMenosZoom.nativeElement.classList.remove('deshabilitado');

    if (limiteAlcanzado)
      this.botonMasZoom.nativeElement.classList.add('deshabilitado');
    else
      this.botonMenosZoom.nativeElement.classList.remove('deshabilitado');
  }

  public reducirZoom() {
    let limiteAlcanzado = this.schedulerHelper.sumarMinutosXStep(this.VALOR_SUMA_X_STEP);

    this.botonMasZoom.nativeElement.classList.remove('deshabilitado');

    if (limiteAlcanzado)
      this.botonMenosZoom.nativeElement.classList.add('deshabilitado');
    else
      this.botonMenosZoom.nativeElement.classList.remove('deshabilitado');
  }

  public setVisibilidadFiltro(visible: boolean) {
    this.filtroVisible = visible;
  }

  public validarFiltro() {
    this.erroresEnFiltro = [];
    this.avisosEnFiltro = [];

    this.validarFechasFiltro();
    this.validarConductoresFiltro();
  }

  private validarFechasFiltro() {
    let valoresFiltro = this.estadoVistaActual.datosFiltro;

    if (!valoresFiltro.fechaInicio) {
      this.erroresEnFiltro.push('Debes introducir una fecha inicio');
    }
    else if (valoresFiltro.fechaInicio.toString() === 'Invalid Date') {
      this.erroresEnFiltro.push('La fecha inicio es inválida');
    }

    if (!valoresFiltro.horaInicio) {
      this.erroresEnFiltro.push('Debes introducir una hora inicio');
    }
    else if (valoresFiltro.horaInicio.toString() === 'Invalid Date') {
      this.erroresEnFiltro.push('La hora inicio es inválida');
    }

    if (!valoresFiltro.fechaFin) {
      this.erroresEnFiltro.push('Debes introducir una fecha fin');
    }
    else if (valoresFiltro.fechaFin.toString() === 'Invalid Date') {
      this.erroresEnFiltro.push('La fecha fin es inválida');
    }

    if (!valoresFiltro.horaFin) {
      this.erroresEnFiltro.push('Debes introducir una hora fin');
    }
    else if (valoresFiltro.horaFin.toString() === 'Invalid Date') {
      this.erroresEnFiltro.push('La hora fin es inválida');
    }

    if (this.erroresEnFiltro.length === 0) {
      let fechaHoraInicioNew = new Date(
        valoresFiltro.fechaInicio.getFullYear(),
        valoresFiltro.fechaInicio.getMonth(),
        valoresFiltro.fechaInicio.getDate(),
        valoresFiltro.horaInicio.getHours(),
        valoresFiltro.horaInicio.getMinutes()
      );
      let fechaHoraFinNew = new Date(
        valoresFiltro.fechaFin.getFullYear(),
        valoresFiltro.fechaFin.getMonth(),
        valoresFiltro.fechaFin.getDate(),
        valoresFiltro.horaFin.getHours(),
        valoresFiltro.horaFin.getMinutes()
      );

      valoresFiltro.fechaHoraInicio = fechaHoraInicioNew;
      valoresFiltro.fechaHoraFin = fechaHoraFinNew;
      let ahora = new Date();
      let milisecondsMax = this.NUM_MAX_RANGO_HORAS_FILTRO * 60 * 60 * 1000;

      if ((fechaHoraInicioNew > fechaHoraFinNew)) {
        this.erroresEnFiltro.push('La fecha y hora Fin debe ser superior a la fecha y hora Origen');
      }

      else if ((fechaHoraFinNew.getTime() - fechaHoraInicioNew.getTime()) > milisecondsMax) {
        this.erroresEnFiltro.push('No se puede visualizar un rango de horas superior a ' + this.NUM_MAX_RANGO_HORAS_FILTRO);
      }

      else if ((fechaHoraInicioNew < ahora)) {
        this.avisosEnFiltro.push('La fecha y hora Origen es anterior');
      }
    }
  }

  private validarConductoresFiltro() {
    let valoresFiltro = this.estadoVistaActual.datosFiltro;
    let hayColision = false;

    if (valoresFiltro.areasTrabajo.length > 0 &&
      valoresFiltro.conductores.length > 0
    ) {
      let listaConductores = this.datosAPI.conductores;

      valoresFiltro.conductores.forEach((conductorFiltro) => {
        let conductor = listaConductores.find(conductor => conductor.id === conductorFiltro.id);
        let coincidencia = valoresFiltro.areasTrabajo.some((areaTrabajoFiltro) => conductor.areaTrabajo && conductor.areaTrabajo.id === areaTrabajoFiltro.id);

        if (!coincidencia)
          hayColision = true;
      });;
    }

    if (hayColision) {
      this.erroresEnFiltro.push('Hay conductores filtrados que no pertenecen a ninguna área de trabajo filtrada');
    }
  }

  public cambiarDesplazamientoDrag() {
    const classBloqueado = 'fa-ban';
    const classLibre = 'fa-arrows-alt';
    const classHorizontal = 'fa-arrows-alt-h';
    const classVertical = 'fa-arrows-alt-v';
    let logoDesplazamiento = this.botonDesplazamiento.nativeElement.getElementsByTagName('i')[0];
    let currentClasslist = logoDesplazamiento.classList;
    let currentClass = currentClasslist[1];

    currentClasslist.remove(currentClass);

    switch (currentClass) {
      case classBloqueado:
        currentClasslist.add(classVertical);
        this.currentMovement = this.VERTICAL_MOVEMENT;
        break;
      case classVertical:
        currentClasslist.add(classBloqueado);
        this.currentMovement = this.BLOCKED_MOVEMENT;
        break;
    }
  }

  public preguntarAplicarFiltro() {
    this.swalService.showMessageQuestion(
      "REALIZAR BÚSQUEDA",
      "<p>¿Desea aplicar el filtro actual?</p><p>Una vez actualizados los resultados, se eliminarán los estados guardados de forma local.</p>"
    ).then((respuesta) => {
      if (respuesta.value && this.erroresEnFiltro.length === 0) {
        this.setVisibilidadFiltro(false);
        this.getDatosFromAPI(true);
      }
    });
  }

  private openModal(template: TemplateRef<any>, claseCss: string, ignoreBackdropClick: boolean = false): BsModalRef<any> {
    return this.modalService.show(
      template,
      Object.assign({}, { class: claseCss, ignoreBackdropClick: ignoreBackdropClick })
    );
  }

  private actualizarServiciosFiltrados() {
    let valoresFiltro = this.estadoVistaActual.datosFiltro;

    let estadosFiltro = valoresFiltro.estado ?
      [valoresFiltro.estado] :
      this.datosAPI.estados;

    let idCargaDatos = this.iniciarCargaDatos('Obteniendo servicios de API');
    this.getServiciosFromApi(
      valoresFiltro.fechaHoraInicio,
      valoresFiltro.fechaHoraFin,
      estadosFiltro,
      valoresFiltro.areasTrabajo,
      valoresFiltro.tiposServicio,
      valoresFiltro.conductores,
      valoresFiltro.vehiculos,
      valoresFiltro.relacionAeropuerto?.value
    ).then(data => {
      let servicios = data.servicios.map((servicio) => ServicioBll.formatearServicioRecibidoDeAPI(servicio, this.datosAPI.configuracion));
      let agrupaciones = data.agrupaciones.map((agrupacion) => AgrupacionCompartidosBll.formatearAgrupacionRecibidaDeAPI(agrupacion, this.datosAPI.configuracion));

      this.recargarScheduler(servicios, agrupaciones);
    })
      .catch(() => this.authService.logout())
      .finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getConductoresFromApi(): Promise<IConductor[]> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo conductores de API');

    return new Promise<IConductor[]>((resolve, reject) => {
      this.asignacionService.getConductoresActivos()
        .subscribe(
          data => {
            if (!data.error) {
              let conductores = data.result['conductores'] as IConductor[];
              resolve(conductores);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getVehiculosFromApi(): Promise<IVehiculo[]> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo vehículos de API');

    return new Promise<IVehiculo[]>((resolve, reject) => {
      this.asignacionService.getVehiculosActivos()
        .subscribe(
          data => {
            if (!data.error) {
              let vehiculos = data.result['vehiculos'] as IVehiculo[];
              resolve(vehiculos);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getAreasTrabajoFromApi(): Promise<IAreaTrabajo[]> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo áreas de trabajo de API');

    return new Promise<IAreaTrabajo[]>((resolve, reject) => {
      this.asignacionService.getAreasTrabajo()
        .subscribe(
          data => {
            if (!data.error) {
              let areasTrabajo = data.result['areasTrabajo'] as IAreaTrabajo[];
              resolve(areasTrabajo);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getTiposServicioFromApi(): Promise<ITipoServicio[]> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo tipos de servicio de API');

    return new Promise<ITipoServicio[]>((resolve, reject) => {
      this.asignacionService.getTiposServicio()
        .subscribe(
          data => {
            if (!data.error) {
              let tiposServicio = data.result['tiposServcicio'] as ITipoServicio[];
              resolve(tiposServicio);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getPermisosConduccionFromApi(): Promise<IPermisoConduccion[]> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo permisos deconducción de API');

    return new Promise<IPermisoConduccion[]>((resolve, reject) => {
      this.asignacionService.getPermisosConduccion()
        .subscribe(
          data => {
            if (!data.error) {
              let permisosConduccion = data.result['permisosConduccion'] as IPermisoConduccion[];
              resolve(permisosConduccion);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getConfiguracionFromApi(): Promise<IConfiguracion> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo configuración de API');

    return new Promise<IConfiguracion>((resolve, reject) => {
      this.asignacionService.getConfiguracion()
        .subscribe(
          data => {
            if (!data.error) {
              let configuracion = data.result['configuracion'] as IConfiguracion;
              resolve(configuracion);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getServiciosFromApi(
    fechaMin: Date,
    fechaMax: Date,
    estados: IEstadoServicio[],
    areasTrabajoId?: IAreaTrabajo[],
    tiposServicio?: ITipoServicio[],
    conductores?: IConductor[],
    vehiculos?: IVehiculo[],
    relacionAeropuerto?: number | null
  ): Promise<{ servicios: IServicio[]; agrupaciones: IAgrupacionCompartidos[] }> {
    return new Promise<{ servicios: IServicio[]; agrupaciones: IAgrupacionCompartidos[] }>((resolve, reject) => {
      this.asignacionService.getServices(
        DateUtils.parseDateToString(fechaMin),
        DateUtils.parseDateToString(fechaMax),
        estados,
        areasTrabajoId,
        tiposServicio,
        conductores,
        vehiculos,
        relacionAeropuerto
      ).subscribe(
        data => {
          if (!data.error) {
            let servicios = data.result['serviciosPrivados'] as IServicio[];
            let agrupaciones = data.result['agrupacionesCompartidos'] as IAgrupacionCompartidos[];
            resolve({ servicios: servicios, agrupaciones: agrupaciones });
          }
          else
            reject()
        },
        error => reject(),
      );
    });
  }

  public async getDatosFromAPI(actualizarServicios = false) {
    let sinErrores = true;

    await Promise.all([
      this.getConductoresFromApi().then(res => this.datosAPI.conductores = res),
      this.getVehiculosFromApi().then(res => this.datosAPI.vehiculos = res),
      this.getAreasTrabajoFromApi().then(res => this.datosAPI.areasTrabajo = res),
      this.getTiposServicioFromApi().then(res => this.datosAPI.tiposServicio = res),
      this.getPermisosConduccionFromApi().then(res => this.datosAPI.permisosConduccion = res),
      this.getConfiguracionFromApi().then(res => this.datosAPI.configuracion = res)
    ]).catch(() => {
      sinErrores = false;
      this.authService.logout();
    });

    if (actualizarServicios) {
      this.actualizarServiciosFiltrados();
    }

    return sinErrores
  }

  private recargarScheduler(servicios: IServicio[], agrupaciones: IAgrupacionCompartidos[]) {
    let valoresFiltro = this.estadoVistaActual.datosFiltro;
    this.estadoVistaActual.limpiarDatosScheduler();
    let conductores = this.datosAPI.conductores;

    // Filtrado de conductores según campos rellenos de filtro
    if (valoresFiltro.conductores && valoresFiltro.conductores.length > 0) {
      conductores = conductores.filter((conductor) =>
        valoresFiltro.conductores.some((conductorFiltro) => conductorFiltro.id === conductor.id)
      );
    }

    if (valoresFiltro.areasTrabajo && valoresFiltro.areasTrabajo.length > 0) {
      conductores = conductores.filter((conductor) =>
        valoresFiltro.areasTrabajo.some((areaTrabajoFiltro) => areaTrabajoFiltro.id === conductor.areaTrabajo.id)
      );
    }

    this.puedeActualizarScheduler = false;
    this.schedulerHelper.iniciarActualizarScheduler(
      valoresFiltro.fechaHoraInicio,
      valoresFiltro.fechaHoraFin,
      servicios,
      agrupaciones,
      conductores
    ).then(() => {
      // Guardamos la referencia de los objetos del scheduler
      this.estadoVistaActual.eventos = this.schedulerHelper.getEvents();
      this.estadoVistaActual.conductores = conductores;
      this.estadoVistaActual.rutas = this.schedulerHelper.getRutas();

      // Reseteamos los estados de la vista
      this.setEstadosVista([this.estadoVistaActual.capturarEstado('INICIAL')]);

      // Guardamos la referencia de los objetos del scheduler TODO CAMBIAR
      this.estadoVistaActual.eventos = this.schedulerHelper.getEvents();
      this.estadoVistaActual.conductores = conductores;
      this.estadoVistaActual.rutas = this.schedulerHelper.getRutas();

      this.puedeActualizarScheduler = true;
    });
  }

  public guardarEstadoVistaActual() {
    if (this.tituloEstadoModalGuardar)
      this.addEstadoVista(this.estadoVistaActual, false, true, this.tituloEstadoModalGuardar);
    else
      this.addEstadoVista(this.estadoVistaActual, false, true);

    this.modalRefModalGuardarEstadoVista.hide();
  }

  public mostrarModalGuardarEstadoVista() {
    this.tituloEstadoModalGuardar = null;
    this.modalRefModalGuardarEstadoVista = this.openModal(this.templateGuardarEstadoVista, 'modal-guardar-estado-vista');
  }

  public mostrarModalEstadosVista() {
    this.estadoVistaSelModal = this.estadosVistaScheduler.length > 0 ? this.estadosVistaScheduler[0] : null;
    this.modalRefModalEstadosVista = this.openModal(this.templateSelectorEstadoVista, 'modal-estados-vistas');
  }

  private restablecerVista(estadoVista: EstadoVistaAsig) {
    this.setEstadoVistaActual(estadoVista);
    this.schedulerHelper.restablecerScheduler(this.estadoVistaActual);
  }

  public eventBotonRestablecerVista(estadoVista: EstadoVistaAsig) {
    this.restablecerVista(estadoVista);

    this.modalRefModalEstadosVista.hide();
  }

  public eventAceptarRecuperarEstadosVista() {
    this.restablecerVista(this.estadoVistaActual);

    this.modalRefModalRecuperarEstadoVista.hide();
  }

  public eventCancelarRecuperarEstadosVista() {
    this.initEstadoVista();
    this.getDatosFromAPI(true);

    this.modalRefModalRecuperarEstadoVista.hide();
  }

  private guardarEstadosVistaLocalStorage() {
    localStorage.setItem(this.KEY_VERSION_AGRUPACIONES, 'true');

    if (this.estadosVistaScheduler.length > 0)
      localStorage.setItem(this.KEY_LOCALSTORAGE_ESTADOS, JSON.stringify(this.estadosVistaScheduler));
    else
      localStorage.removeItem(this.KEY_LOCALSTORAGE_ESTADOS);
  }

  private leerEstadosVistaLocalStorage(): EstadoVistaAsig[] | null {
    let estadosVistaScheduler = localStorage.getItem(this.KEY_LOCALSTORAGE_ESTADOS);
    let versionAgrupaciones = localStorage.getItem(this.KEY_VERSION_AGRUPACIONES);

    if (estadosVistaScheduler && versionAgrupaciones) {
      let arrayEstados = JsonUtils.fromJson(estadosVistaScheduler);
      arrayEstados = arrayEstados.map((estado) => EstadoVistaAsig.fromJsonObject(estado));

      return arrayEstados;
    }

    return null;
  }

  private setEstadoVistaActual(newEstadoActual: EstadoVistaAsig, tomarComoReferencia: boolean = false, capturarEstado: boolean = false, nuevoTitulo: string = '(SIN TÍTULO)') {
    if (tomarComoReferencia)
      this.estadoVistaActual = newEstadoActual;
    else
      this.estadoVistaActual = capturarEstado ? newEstadoActual.capturarEstado(nuevoTitulo) : newEstadoActual.clonar();
  }

  private setEstadosVista(newEstadosVista: EstadoVistaAsig[], guardarEnLocalStorage: boolean = true) {
    this.setEstadoVistaActual(newEstadosVista[0]);
    this.setEstadoVistaSinAlterar(newEstadosVista[newEstadosVista.length - 1]);
    this.estadosVistaScheduler = newEstadosVista.map(estadoVista => estadoVista.clonar());

    if (guardarEnLocalStorage)
      this.guardarEstadosVistaLocalStorage();
  }

  private addEstadoVista(newEstadoVista: EstadoVistaAsig, tomarComoReferencia: boolean = false, capturarEstado: boolean = false, nuevoTitulo: string = '(SIN TÍTULO)') {
    if (tomarComoReferencia)
      this.estadosVistaScheduler.unshift(newEstadoVista);
    else
      this.estadosVistaScheduler.unshift(capturarEstado ? newEstadoVista.capturarEstado(nuevoTitulo) : newEstadoVista.clonar());

    this.guardarEstadosVistaLocalStorage();
  }

  private setEstadoVistaSinAlterar(newEstadoSinAlterar: EstadoVistaAsig) {
    this.estadoVistaSinAlterar = Object.freeze(newEstadoSinAlterar.clonar());
  }

  public preguntarSubirCambiosBD() {
    this.swalService.showMessageQuestion(
      "SUBIR CAMBIOS",
      "<p>¿Desea subir los cambios actuales?</p><p>Una vez se hayan guardado los cambios, se eliminarán los estados guardados de forma local.</p>"
    ).then((respuesta) => {
      if (respuesta.value) {
        this.subirCambiosBD();
      }
    });
  }

  private subirCambiosBD() {
    let servicios = this.estadoVistaActual.getServiciosAlterados();
    let agrupaciones = this.estadoVistaActual.getAgrupacionesAlteradas();
    agrupaciones.forEach(agrupacion => servicios = servicios.concat(AgrupacionCompartidosBll.getServicios(agrupacion)));

    let idCargaDatos = this.iniciarCargaDatos('Guardando en la nube');
    this.asignacionService.putServicios(servicios).subscribe(
      data => {
        if (!data.error) {
          this.swalService.showMessageInfo('Cambios actualizados con éxito');
          this.getDatosFromAPI(true);
        }
        else {
          this.swalService.showMessageError('Error al guardar los cambios');
        }
      },
      error => {
        this.swalService.showMessageError('Error al guardar los cambios');
      },
      () => this.finalizarCargaDatos(idCargaDatos)
    );
  }

  public iniciarCargaDatos(mensaje: string): number {
    let nextId = Math.max(...this.mensajesCargasDatosEnCurso.map((mensaje) => mensaje.id)) + 1;
    this.mensajesCargasDatosEnCurso.push({ id: nextId, mensaje: mensaje });

    return nextId;
  }

  public finalizarCargaDatos(idCargaDatos: number) {
    let index = this.mensajesCargasDatosEnCurso.findIndex((mensaje) => mensaje.id === idCargaDatos);

    if (index > -1) {
      this.mensajesCargasDatosEnCurso.splice(index, 1);
    }
  }

  private mostrarModalAsignacionVehiculo(listaVehiculos: IVehiculo[], indicesSeleccionados: number[]) {
    return this.swalService.showModalWithSelect(
      null,
      'ASIGNACIÓN DE VEHÍCULO',
      'Vehiculo asignado',
      this.containerRoot,
      listaVehiculos,
      false,
      'SIN ASIGNAR',
      indicesSeleccionados,
      VehiculoBll.formatToSelectValue
    );
  }

  private mostrarModalAsignacionConductor(listaConductores: IConductor[], indicesSeleccionados: number[], esComprobacion = false) {
    return this.swalService.showModalWithSelect(
      null,
      (esComprobacion ? 'COMPROBACIÓN' : 'ASIGNACIÓN') + ' DE CONDUCTOR',
      'Conductor asignado',
      this.containerRoot,
      listaConductores,
      false,
      'SIN ASIGNAR',
      indicesSeleccionados,
      ConductorBll.formatToSelectValue
    );
  }

  private mostrarModalAsignacionVehiculoEvento(eventScheduler: EventoSchedulerReserva) {
    let vehiculosCompatibles = this.datosAPI.vehiculos.filter((vehiculo) => eventScheduler.esCompatibleConVehiculo(vehiculo));
    let vehiculoAsignado = eventScheduler.getVehiculo();
    let indexSelected = vehiculoAsignado ?
      vehiculosCompatibles.findIndex((vehiculo) => vehiculo.id === vehiculoAsignado.id) :
      -1;
    let initialSelectedIndexes = indexSelected >= 0 ? [indexSelected] : [];

    this.mostrarModalAsignacionVehiculo(vehiculosCompatibles, initialSelectedIndexes).then((resultado) => {
      if (resultado.isConfirmed) {
        let vehiculoNuevo = resultado.value;
        eventScheduler.asignarVehiculo(vehiculoNuevo);

        this.comprobarCambiosEnEventos();
        this.schedulerHelper.updateView();
      }
    });
  }

  private mostrarModalEliminarVehiculoEvento(eventScheduler: EventoSchedulerReserva) {
    let vehiculoAsignado = eventScheduler.getVehiculo();
    let titulo = "ELIMINAR VEHÍCULO";
    let texto = "<p>¿Desea eliminar el vehículo con matricula " + vehiculoAsignado.matricula + "?</p>";

    this.swalService.showMessageQuestion(titulo, texto).then((resultado) => {
      if (resultado.isConfirmed) {
        eventScheduler.asignarVehiculo(null);

        this.comprobarCambiosEnEventos();
        this.schedulerHelper.updateView();
      }
    });
  }

  private mostrarModalEditarServicio(eventScheduler: EventoSchedulerReserva) {
    let servicio = eventScheduler.data;
    let servicioMod = Object.assign({}, eventScheduler.data);

    this.swalService.showModalModifyService(servicioMod, this.containerRoot).then((resultado) => {
      if (resultado.isConfirmed) {
        Object.assign(servicio, servicioMod);
        ServicioBll.sincronizarFechasConInicioYMargenes(servicio);

        eventScheduler.sincronizar();
        this.comprobarCambiosEnEventos();
        this.schedulerHelper.updateEvent(eventScheduler.id);
      }
    });
  }

  public mostrarModalAyuda() {
    let titulo = "IFORMACIÓN DE USO";
    let texto = "<h3>Vista scheduler</h3>";
    texto += "<p>(Descripción sobre lo que se muestra)</p>";

    texto += "<h4>Opciones sobre la gráfica</h4>";
    texto += "<p>(Descripción sobre las opciones)</p>";
    texto += "<h5>Opción de filtrado</h5>";
    texto += "<p>(Descripción de filtrado)</p>";
    texto += "<h5>Opción de zoom</h5>";
    texto += "<p>(Descripción de zoom)</p>";

    texto += "<h4>Opciones sobre las rutas</h4>";
    texto += "<h5>(Hablar sobre generación automática)</h5>";
    texto += "<h5>Opción de creación de rutas</h5>";
    texto += "<p>(Descripción de creación de rutas)</p>";
    texto += "<h5>Opción de edición de rutas</h5>";
    texto += "<p>(Descripción de edición de rutas)</p>";

    texto += "<h4>Opciones sobre los servicios</h4>";
    texto += "<h5>(Introducción breve)</h5>";
    texto += "<h5>Opción de asignación conductor</h5>";
    texto += "<p>(Descripción de asignación conductor)</p>";
    texto += "<h5>Opción de asignación conductor</h5>";
    texto += "<p>(Descripción de asignación conductor)</p>";

    this.swalService.showMessageInfo(titulo, texto);
  }

  public comprobarCambiosEnEventos() {
    let eventosIniciales = this.estadoVistaSinAlterar.eventos;
    let eventosActuales = this.estadoVistaActual.eventos;
    let eventosActualesSinModificar: EventoSchedulerReserva[] = [];
    let eventosActualesModificados: EventoSchedulerReserva[] = [];

    eventosActuales.filter(evento => evento instanceof EventoSchedulerReserva)
        .map(evento => evento as EventoSchedulerReserva)
        .forEach((eventoActual) => {
      let modificado = eventosIniciales.some((eventoInicial) => {
        return eventoInicial.id === eventoActual.id
          && eventoActual.seHaModificado(eventoInicial);
      });

      modificado ?
        eventosActualesModificados.push(eventoActual) :
        eventosActualesSinModificar.push(eventoActual);
    });

    eventosActualesSinModificar.forEach((evento) => {
      if (evento.opciones.modificado) {
        evento.opciones.modificado = false;
        this.schedulerHelper.updateEvent(evento.id);
      }
    });
    eventosActualesModificados.forEach((evento) => {
      if (!evento.opciones.modificado) {
        evento.opciones.modificado = true;
        this.schedulerHelper.updateEvent(evento.id);
      }
    });
  }

  public mostrarDialogoCambios() {
    let eventosIniciales = this.estadoVistaSinAlterar.eventos;
    let eventosAlterados = this.estadoVistaActual.getEventosReservasAlterados();

    let titulo = "SERVICIOS Y AGRUPACIONES ALTERADOS";
    let texto = "<table class='tabla-dialogo'>";
    texto += '<tr><th>ID SERVICIO</th><th>CAMBIOS</th></tr>';

    eventosAlterados.forEach((eventoAlterado) => {
      let eventoInicial = eventosIniciales.find((eventoInicial) => eventoInicial.id === eventoAlterado.id);
      let cambios = eventoAlterado.getCambios(eventoInicial);
      texto += '<tr><td rowspan="' + cambios.mensajesCambios.length + '">' + cambios.id + '</td><td>' + cambios.mensajesCambios[0] + '</td></tr>';

      for (let i = 1; i < cambios.mensajesCambios.length; i++)
        texto += '<tr><td>' + cambios.mensajesCambios[i] + '</td></tr>';
    })

    texto += '</table>';

    this.swalService.showMessageInfo(titulo, texto);
  }

  public hayEstadosGuardadosPorUsuario(): boolean {
    return this.estadosVistaScheduler.some(estadoVista => estadoVista.titulo !== 'INICIAL');
  }

  public hayEventosAlterados(): boolean {
    return this.estadoVistaActual.getEventosReservasAlterados().length > 0;
  }
}
