import { UserInterfaceUtils } from "../../utils/user-interface.util";
import { IEventoScheduler, OpcionesEventoScheduler, TipoDataEventoScheduler } from "./evento-scheduler.interface";
import { EventoScheduler } from "./evento-schedulerx";

export class EventoSchedulerMarcador extends EventoScheduler {
    
    public id: string;
    public start_date: Date;
    public end_date: Date;
    public text: string;
    public section_id: string;
    public color: string;
    public opciones: OpcionesEventoScheduler;

    public data: any;
    public readonly TIPO_DATA: TipoDataEventoScheduler;

    constructor(
        id: string,
        data: any,
        section_id: string,
        text: string,
        startDate: Date,
        endDate: Date,
        colorHex: string,
        opciones: OpcionesEventoScheduler = { modificado: false }
    ) {

        super(
            id,
            data,
            TipoDataEventoScheduler.MARCADOR,
            section_id,
            text,
            colorHex,
            opciones
        );

        this.start_date = startDate;
        this.end_date = endDate;

        this.sincronizar();
    }

    public sincronizar() {
    }

    clone() {
        return new EventoSchedulerMarcador(
            this.id,
            this.data,
            this.section_id,
            this.text,
            this.start_date,
            this.end_date,
            this.color,
            this.opciones
        );
    }

    getTemplateTooltip(): string {
        let texto = "<div class='tooltipSchedulerContainer'><span>" + this.text + "</span></div>";
        return texto;
    }

    getTemplateBar(fechaLimiteSchedulerInicial: Date, fechaLimiteSchedulerFinal: Date): string {
        let colorFondoClaro = UserInterfaceUtils.esColorHexFondoClaro(this.color);

        let plantilla = "<div class='event_bar_content' style='color: #" + (colorFondoClaro ? '000000' : 'FFFFFF') + ";'>";
        plantilla += "<span>" + this.text + "</span>";

        plantilla += "</div>";

        //PATRÓN FONDO
        plantilla += "<div class='event_bar_margen_tiempo inicial' style='width:100%'></div>";

        return plantilla;
    }

    public getOpcionesMenuContextual() {
        let contextMenuItems = [] as { label: string, value: string }[];
        return contextMenuItems;
    }
}
