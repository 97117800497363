import { AgrupacionCompartidosBll } from "../../business-logic-layer/agrupacion-compartidos.bll";
import { RutaEventos } from "../../business-logic-layer/asignacion-automatica-servicios/ruta-servicios.class";
import { ServicioBll } from "../../business-logic-layer/servicio.bll";
import { IAgrupacionCompartidos } from "../../entity/agrupacion-compartidos.interface";
import { IAreaTrabajo } from "../../entity/area-trabajo.interface";
import { IConductor } from "../../entity/conductor.interface";
import { IServicio } from "../../entity/servicio.interface";
import { ITipoServicio } from "../../entity/tipo-servicio.interface";
import { IVehiculo } from "../../entity/vehiculo.interface";
import { DateUtils } from "../../utils/date.util";
import { UserInterfaceUtils } from "../../utils/user-interface.util";
import { EventoSchedulerReserva } from "./evento-scheduler-reserva.class";
import { OpcionesEventoScheduler, TipoDataEventoScheduler } from "./evento-scheduler.interface";

export class EventoSchedulerAgrupacion extends EventoSchedulerReserva {
    data: IAgrupacionCompartidos;

    constructor(
        data: IAgrupacionCompartidos,
        section_id: string,
        text: string,
        opciones: OpcionesEventoScheduler = { modificado: false },
        estado?: number
    ) {
        super(
            'evento_agrupacion_' + data.id,
            data,
            TipoDataEventoScheduler.AGRUPACION,
            section_id,
            text,
            opciones,
            estado
        );
    }

    public sincronizar() {
        this.start_date = this.data.dateFechaInicio;
        this.end_date = this.data.dateFechaFinConMargen;
    }

    clone() {
        return new EventoSchedulerAgrupacion(
            this.data,
            this.section_id,
            this.text,
            this.opciones,
            this.estado
        );
    }

    getTemplateTooltip(): string {
        let agrupacion: IAgrupacionCompartidos = this.data;
        let tipo = AgrupacionCompartidosBll.getNombreTipo(agrupacion);

        let texto = "<div class='tooltipSchedulerContainer'><table class='tooltipSchedulerTable'>";
        texto += "<tr><th colspan='3'>AGRUPACIÓN " + agrupacion.id + " (" + tipo + ")</th></tr>";
        let iconosString = AgrupacionCompartidosBll.getIconosDatos(agrupacion).join('');
        if (this.opciones.modificado)
            iconosString = "<i class='fas fa-pen icono-dato-servicio'></i>" + iconosString;

        texto += "<tr><th colspan='3'>" + iconosString + "</th></tr>";
        texto += "<tr><th>A.T.</th><td colspan='2'>" + (agrupacion.areaTrabajo ? agrupacion.areaTrabajo.nombre : '-') + "</td></tr>";

        texto += "<tr><th>T.S.</th>";
        texto += agrupacion.tipoServicio ?
            "<td>" + agrupacion.tipoServicio.nombre + " [" + agrupacion.tipoServicio.permisoConduccion.nombre + "] <td style='background-color: " + agrupacion.tipoServicio.colorHex + ";'></td></td>" :
            "<td colspan='2'>-</td>";
        texto += "</tr>";

        let extraDireccionOrigen = "";
        let extraDireccionDestino = "";

        let extraDireccion = "[Nº Vuelo " + (agrupacion.numVuelo ? agrupacion.numVuelo : '-') + "]";
        if (agrupacion.esSalida === ServicioBll.TIPO_ES_LLEGADA)
            extraDireccionOrigen += extraDireccion;
        else if (agrupacion.esSalida === ServicioBll.TIPO_ES_SALIDA)
            extraDireccionDestino += extraDireccion;

        let duracionSegundosConMargen = (agrupacion.dateFechaFinConMargen.getTime() - agrupacion.dateFechaInicio.getTime()) / 1000;

        texto += "<tr><th>ORI.</th><td>" + DateUtils.parseDateToString(agrupacion.dateFechaInicio) + "</td><td>" + agrupacion.origen + " " + extraDireccionOrigen + "</td></tr>";
        texto += "<tr><th>DES.</th><td>" + DateUtils.parseDateToString(agrupacion.dateFechaFin) + "</td><td>" + agrupacion.destino + " " + extraDireccionDestino + "</td></tr>";
        texto += "<tr><th>DUR.</th><td>" + DateUtils.formatearDuracionSegundos(agrupacion.duracionSegundos) + "</td><td>" + DateUtils.formatearDuracionSegundos(duracionSegundosConMargen) + " (Margen Incl.)</td></tr>";
        texto += "<tr><th>PAX.</th><td colspan='2'>" + agrupacion.numeroAdultos + " adultos, " + agrupacion.numeroNinyos + " niños, " + agrupacion.numeroBebes + " bebés</td></tr>";

        let textoVehiculo = agrupacion.vehiculo ? agrupacion.vehiculo.alias + " [" + agrupacion.vehiculo.matricula + "]" : "-";
        texto += "<tr><th>VEH.</th><td colspan='2'>" + textoVehiculo + "</td></tr>";

        texto += "<tr><th>SER.</th><td colspan='2'>(" + AgrupacionCompartidosBll.getServicios(agrupacion).map(servicio => servicio.id).join(') (') + ")</td></tr>";
        texto += "</table></div>";

        return texto;
    }

    getTemplateBar(fechaLimiteSchedulerInicial: Date, fechaLimiteSchedulerFinal: Date): string {
        let agrupacion = this.data;
        let segundosInicialesFueraDeRango = this.getSegundosInicialesFueraDeRango(fechaLimiteSchedulerInicial);
        let segundosFinalesFueraDeRango = this.getSegundosFinalesFueraDeRango(fechaLimiteSchedulerFinal);

        let prefijoTipo = AgrupacionCompartidosBll.getPrefijoTipo(agrupacion);
        let iconoLLegadaSalida = '';

        if (agrupacion.esSalida === ServicioBll.TIPO_ES_LLEGADA)
            iconoLLegadaSalida = "<i class='fas fa-plane-arrival icono-dato-servicio'></i>";
        else if (agrupacion.esSalida === ServicioBll.TIPO_ES_SALIDA)
            iconoLLegadaSalida = "<i class='fas fa-plane-departure icono-dato-servicio'></i>";

        let colorFondoClaro = agrupacion.tipoServicio ? UserInterfaceUtils.esColorHexFondoClaro(agrupacion.tipoServicio.colorHex) : false;

        let plantilla = "<div class='event_bar_content' style='color: #" + (colorFondoClaro ? '000000' : 'FFFFFF') + ";'>";
        plantilla += iconoLLegadaSalida;
        plantilla += "<span class='prefijo_tipo'>" + prefijoTipo + "</span>";
        plantilla += "<i class='fas fa-object-group icono-dato-servicio'></i>";
        plantilla += "<span>" + agrupacion.id + "</span>";

        // ICONOS
        plantilla += AgrupacionCompartidosBll.getIconosDatos(agrupacion, false).join('');

        plantilla += "</div>";

        // MARGENES
        let duracionSegundosConMargenVisible = DateUtils.getSegundosEntreFechas(agrupacion.dateFechaInicio, agrupacion.dateFechaFinConMargen) - segundosInicialesFueraDeRango - segundosFinalesFueraDeRango;
        let margenSegundosInicialesVisible = (agrupacion.margenSegundosInicial) - segundosInicialesFueraDeRango;
        let margenSegundosFinalesVisible = (agrupacion.margenSegundosFinal) - segundosFinalesFueraDeRango;

        if (margenSegundosInicialesVisible > 0) {
            let porcentaje = (margenSegundosInicialesVisible / duracionSegundosConMargenVisible) * 100;
            plantilla += "<div class='event_bar_margen_tiempo inicial' style='width:" + porcentaje + "%'></div>";
        }
        if (margenSegundosFinalesVisible > 0) {
            let porcentaje = (margenSegundosFinalesVisible / duracionSegundosConMargenVisible) * 100;
            plantilla += "<div class='event_bar_margen_tiempo final' style='width:" + porcentaje + "%'></div>";
        }

        return plantilla;
    }

    public getCambios(eventoSinAlterar: EventoSchedulerAgrupacion): { id: number, mensajesCambios: string[] } {
        let mensajeCambios = AgrupacionCompartidosBll.getMensajesCambios(eventoSinAlterar.data, this.data);
        return {
            id: this.data.id,
            mensajesCambios: mensajeCambios
        }
    }

    public asignarIdsConductores(idConductores: number[]) {
        this.data.idConductores = idConductores;
        AgrupacionCompartidosBll.getServicios(this.data)
            .forEach(servicio => servicio.idConductores = idConductores);
    }

    public asignarVehiculo(vehiculo: IVehiculo) {
        this.data.vehiculo = vehiculo;
        AgrupacionCompartidosBll.getServicios(this.data)
            .forEach(servicio => servicio.vehiculo = vehiculo);
    }

    public getOpcionesMenuContextual() {
        let servicio = this.data;

        const tieneVehiculoAsignado = servicio.vehiculo != null;

        let contextMenuItems = [] as { label: string, value: string }[];
        contextMenuItems.push({
            label: (tieneVehiculoAsignado ? 'Reasignar' : 'Asignar') + ' vehículo',
            value: EventoSchedulerReserva.CONTEXT_MENU_OPCION_ASIGNAR_VEHICULO
        });

        if (tieneVehiculoAsignado)
            contextMenuItems.push({
                label: 'Eliminar vehículo',
                value: EventoSchedulerReserva.CONTEXT_MENU_OPCION_ELIMINAR_VEHICULO
            });

        return contextMenuItems;
    }

    public esCompatibleConVehiculo(vehiculo: IVehiculo): boolean {
        return AgrupacionCompartidosBll.esCompatibleConVehiculo(this.data, vehiculo);
    }

    public esCompatibleConConductor(conductor: IConductor): boolean {
        return AgrupacionCompartidosBll.esCompatibleConConductor(this.data, conductor);
    }

    public esCompatibleConRuta(ruta: RutaEventos): boolean {
        return ruta.esCompatibleConAgrupacion(this.data);
    }

    public getIdConductores(): number[] {
        return this.data.idConductores;
    }

    public getVehiculo(): IVehiculo | null {
        return this.data.vehiculo;
    }

    public getTipoServicio(): ITipoServicio | null {
        return this.data.tipoServicio
    }

    public getAreaTrabajo(): IAreaTrabajo | null {
        return this.data.areaTrabajo
    }

    public getDataId(): number {
        return this.data.id
    }

    public getCoordenadasOrigen(): { latitud: number; longitud: number; } {
        return {
            latitud: this.data.latitudOrigen,
            longitud: this.data.longitudOrigen
        }
    }

    public getCoordenadasDestino(): { latitud: number; longitud: number; } {
        return {
            latitud: this.data.latitudDestino,
            longitud: this.data.longitudDestino
        }
    }
}
