<div #containerRoot class="content-wrapper">
	<div id="filtro_container" [ngStyle]="filtroVisible? { 'max-height': '1000px', 'overflow': 'hidden' }: {}">
		<form class="row">

			<evtc-custom-datepicker class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3 row" label="Fecha inicio"
				[(selectedDate)]="estadoVistaActual.datosFiltro.fechaInicio" (onSelectionChanged)="validarFiltro()">
			</evtc-custom-datepicker>
			<evtc-custom-timepicker class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3 row" label="Hora inicio"
				[(selectedTime)]="estadoVistaActual.datosFiltro.horaInicio" (onSelectionChanged)="validarFiltro()">
			</evtc-custom-timepicker>

			<evtc-custom-datepicker class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3 row" label="Fecha fin"
				[(selectedDate)]="estadoVistaActual.datosFiltro.fechaFin" (onSelectionChanged)="validarFiltro()">
			</evtc-custom-datepicker>
			<evtc-custom-timepicker class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3 row" label="Hora fin"
				[(selectedTime)]="estadoVistaActual.datosFiltro.horaFin" (onSelectionChanged)="validarFiltro()">
			</evtc-custom-timepicker>

			<evtc-custom-select class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3" label="Estado"
				[items]="datosAPI.estados" [(selectedItemOrItems)]="estadoVistaActual.datosFiltro.estado"
				placeHolder="TODOS" [multiple]="false" [formatValue]="EstadoServicioBll.formatToSelectValue"
				(onSelectionChanged)="validarFiltro()"></evtc-custom-select>

			<evtc-custom-select class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3" label="Área de Trabajo"
				[items]="datosAPI.areasTrabajo" [(selectedItemOrItems)]="estadoVistaActual.datosFiltro.areasTrabajo"
				placeHolder="TODAS" [multiple]="true" [formatValue]="AreaTrabajoBll.formatToSelectValue"
				(onSelectionChanged)="validarFiltro()"></evtc-custom-select>

			<evtc-custom-select class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3" label="Tipo Servício"
				[items]="datosAPI.tiposServicio" [(selectedItemOrItems)]="estadoVistaActual.datosFiltro.tiposServicio"
				placeHolder="TODOS" [multiple]="true" [formatValue]="TipoServicioBll.formatToSelectValue"
				(onSelectionChanged)="validarFiltro()"></evtc-custom-select>

			<evtc-custom-select class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3" label="Conductores"
				[items]="datosAPI.conductores" [(selectedItemOrItems)]="estadoVistaActual.datosFiltro.conductores"
				placeHolder="TODOS" [multiple]="true" [formatValue]="ConductorBll.formatToSelectValue"
				(onSelectionChanged)="validarFiltro()"></evtc-custom-select>

			<evtc-custom-select class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3" label="Vehículos"
				[items]="datosAPI.vehiculos" [(selectedItemOrItems)]="estadoVistaActual.datosFiltro.vehiculos"
				placeHolder="TODOS" [multiple]="true" [formatValue]="VehiculoBll.formatToSelectValue"
				(onSelectionChanged)="validarFiltro()"></evtc-custom-select>

			<evtc-custom-select class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3"
				label="Llegada/Salida de Aeropuerto" [items]="datosAPI.relacionesConAeropuerto"
				[(selectedItemOrItems)]="estadoVistaActual.datosFiltro.relacionAeropuerto" placeHolder="CUALQUIERA"
				[multiple]="false" (onSelectionChanged)="validarFiltro()"></evtc-custom-select>

			<!-- <evtc-custom-select class="formField col-xs-12 col-sm-6 col-md-4 col-lg-3"
				label="Jornada de Conductores"
				[items]="datosAPI.jornadasConductores"
				[(selectedItemOrItems)]="estadoVistaActual.datosFiltro.jornadaConductores"
				placeHolder="CUALQUIERA"
				[multiple]="false"
				(onSelectionChanged)="validarFiltro()"></evtc-custom-select> -->

			<div *ngIf="erroresEnFiltro.length > 0" class="errores-formulario-container form-group col-12">
				<ul *ngFor="let error of erroresEnFiltro">
					<li>{{error}}.</li>
				</ul>
			</div>

			<div *ngIf="avisosEnFiltro.length > 0" class="avisos-formulario-container form-group col-12">
				<ul *ngFor="let aviso of avisosEnFiltro">
					<li>{{aviso}}.</li>
				</ul>
			</div>

			<div class="form-group col-12">
				<button type="button" #boton_aceptar_filtro id="boton_aceptar_filtro"
					class="btn btn-outline-primary col-12" (click)="preguntarAplicarFiltro()"
					[disabled]="erroresEnFiltro.length > 0? true: null">
					Aplicar Filtro
				</button>
			</div>
		</form>
	</div>
	<div id="scheduler_container" class="dhx_cal_container">
		<div class="dhx_cal_navline">

			<div class="opciones_extra_container col-12">
				<div class="opciones_extra_derecha_container col-4">
					<div id='boton_mostrar_filtro' class="opcion_grafica" role='button' data-toggle="tooltip"
						data-placement="bottom" title="Mostrar filtros del listado de eventos"
						(click)="setVisibilidadFiltro(!filtroVisible)">
						<i class="fas fa-filter"></i>
					</div>

					<div #desplazamiento_timeline_container id='desplazamiento_timeline_container'
						class="opcion_grafica" role='button' data-toggle="tooltip" data-placement="bottom"
						title="Alterar desplazamiento de eventos al arrastrar con el ratón"
						(click)="cambiarDesplazamientoDrag()">
						<i class="fas fa-ban"></i>
					</div>

					<div #boton_mas_zoom id='boton_mas_zoom' class="opcion_grafica" role='button' data-toggle="tooltip"
						data-placement="bottom" title="Ampliar Zoom" (click)="aumentarZoom()">
						<i class="fas fa-plus"></i>
					</div>

					<div #boton_menos_zoom id='boton_menos_zoom' class="opcion_grafica" role='button'
						data-toggle="tooltip" data-placement="bottom" title="Reducir Zoom" (click)="reducirZoom()">
						<i class="fas fa-minus"></i>
					</div>

					<!-- <div id='boton_ayuda' class="opcion_grafica" role='button' data-toggle="tooltip"
						data-placement="bottom" title="Mostrar ayuda" (click)="mostrarModalAyuda()">
						<i class="fas fa-info"></i>
					</div> -->
				</div>

				<div class="opciones_extra_izquierda_container col-4">
					<div id='boton_lista_cambios'
						[ngClass]="!hayEventosAlterados()?'opcion_grafica deshabilitado': 'opcion_grafica'"
						role='button' data-toggle="tooltip" data-placement="bottom" title="Listar cambios"
						(click)="mostrarDialogoCambios()">
						<i class="fas fa-list">
							<span>{{estadoVistaActual.getEventosReservasAlterados().length}}</span>
						</i>
					</div>
					<div id='boton_lista_estados' class="opcion_grafica" role='button' data-toggle="tooltip"
						data-placement="bottom" title="Restablecer a estado anterior"
						(click)="mostrarModalEstadosVista()">
						<i class="fas fa-code-branch"></i>
					</div>

					<div id='boton_guardar_local' class="opcion_grafica" role='button' data-toggle="tooltip"
						data-placement="bottom" title="Guardar estado actual"
						(click)="mostrarModalGuardarEstadoVista()">
						<i class="fas fa-save"></i>
					</div>

					<div id='boton_guardar_bd'
						[ngClass]="!hayEventosAlterados()?'opcion_grafica deshabilitado': 'opcion_grafica'"
						role='button' data-toggle="tooltip" data-placement="bottom" title="Subir cambios actuales"
						(click)="preguntarSubirCambiosBD()">
						<i class="fas fa-cloud-upload-alt" aria-hidden="true"></i>
					</div>

					<div id='boton_refresh' class="opcion_grafica" role='button' data-toggle="tooltip"
						data-placement="bottom" title="Sincronizar datos" (click)="getDatosFromAPI(true)">
						<i class="fa fa-sync"></i>
					</div>
				</div>
			</div>

			<div class="dhx_cal_date"></div>
		</div>
		<div class="dhx_cal_header">
		</div>
		<div class="dhx_cal_data">
		</div>
	</div>
</div>

<div id="containerCarga" *ngIf="mensajesCargasDatosEnCurso.length > 0">
	<div class="dialogoCarga">
		<svg class='iconoCarga' width="50" height="50" viewBox="0 0 50 50">
			<path fill="#0288d1"
				d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z">
				<animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.8s"
					repeatCount="indefinite" />
			</path>
		</svg>
		<ul class='mensajesCarga'>
			<li *ngFor="let mensajeCargasDatos of mensajesCargasDatosEnCurso; let indice=index">
				{{mensajeCargasDatos.mensaje}}
			</li>
		</ul>
	</div>
</div>

<evtc-context-menu #contextMenuScheduler></evtc-context-menu>

<ng-template #template_recuperar_estado_vista>
	<div class="modal-body text-center">
		<p>¿Desea recuperar sus últimos estados?</p>
		<table class="tabla-dialogo">
			<tr>
				<th>HORA</th>
				<th>TÍTULO</th>
			</tr>
			<tr *ngFor="let estadoVista of estadosVistaScheduler; let indice=index"
				[class.ultimo-estado]="indice === 0">
				<td>{{DateUtils.parseDateToString(estadoVista.fecha, 'DD-MM-YYYY H:mm:ss')}}</td>
				<td>{{(estadoVista.titulo?estadoVista.titulo:'')}}</td>
			</tr>
		</table>

		<div class='modal-buttons-container col-xs-12'>
			<button type="button" class="btn btn-primary" (click)="eventAceptarRecuperarEstadosVista()">Yes</button>
			<button type="button" class="btn btn-light" (click)="eventCancelarRecuperarEstadosVista()">No</button>
		</div>
	</div>
</ng-template>

<ng-template #template_guardar_estado_vista>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Guardar estado</h4>
		<button type="button" class="btn-close close pull-right" aria-label="Close"
			(click)="modalRefModalGuardarEstadoVista?.hide()">
			<span aria-hidden="true" class="visually-hidden">&times;</span>
		</button>
	</div>
	<div class="modal-body modal-lg">
		<div class="form-group col-xs-12 row">
			<label class="form-control-label truncate" for="titulo-estado">Título</label>
			<div class="input-group">
				<input type="text" class="form-control" name="titulo-estado" [(ngModel)]="tituloEstadoModalGuardar">
			</div>
		</div>

		<div class='modal-buttons-container col-xs-12'>
			<button type="button" class="btn btn-primary" (click)="guardarEstadoVistaActual()">GUARDAR</button>
			<button type="button" class="btn btn-light"
				(click)="modalRefModalGuardarEstadoVista?.hide()">CANCELAR</button>
		</div>
	</div>
</ng-template>

<ng-template id="template_selector_estado_vista" #template_selector_estado_vista>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Estados guardados</h4>
		<button type="button" class="btn-close close pull-right" aria-label="Close"
			(click)="modalRefModalEstadosVista?.hide()">
			<span aria-hidden="true" class="visually-hidden">&times;</span>
		</button>
	</div>
	<div class="modal-body modal-lg">

		<div class="form-group col-xs-12 row">
			<label class="form-control-label truncate" for="estado_vista">Estado Vista</label>
			<ng-select class="input-group col-sm-12" name="estado_vista" [items]="estadosVistaScheduler"
				[multiple]="false" [clearable]="false" [(ngModel)]="estadoVistaSelModal"
				placeholder="SIN ESTADOS GUARDADOS">

				<ng-template ng-label-tmp let-item="item" let-clear="clear">
					<span class="ng-value-label">
						{{ DateUtils.parseDateToString(item.fecha, 'DD-MM-YYYY H:mm:ss') + (item.titulo?' - ' +
						item.titulo:'') }}
					</span>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
					<span>
						{{ DateUtils.parseDateToString(item.fecha, 'DD-MM-YYYY H:mm:ss') + (item.titulo?' - ' +
						item.titulo:'') }}
					</span>
				</ng-template>
			</ng-select>
		</div>

		<div class='modal-buttons-container col-xs-12'>
			<button type="button" class="btn btn-primary" *ngIf="this.estadoVistaSelModal"
				(click)="eventBotonRestablecerVista(estadoVistaSelModal)">RESTABLECER</button>
			<button type="button" class="btn btn-light" (click)="modalRefModalEstadosVista?.hide()">CANCELAR</button>
		</div>
	</div>
</ng-template>