<ng-template #template>
    <div class="swal2-popup swal2-modal swal2-container-custom swal2-icon-info swal2-show" style="display: grid;">

        <div class="swal2-icon swal2-info swal2-icon-show" style="display: flex;">
            <div class="swal2-icon-content">i</div>
        </div>

        <h2 class="swal2-title" id="swal2-title" style="display: block;" *ngIf="header">{{header}}</h2>

        <div class="swal2-html-container" id="swal2-html-container" style="display: block;" *ngIf="body">{{body}}</div>

        <div class="swal2-actions" style="display: flex;">
            <button type="button" class="swal2-confirm swal2-styled" (click)="modalRef.hide()">Aceptar</button>
        </div>

    </div>
</ng-template>