import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'evtc-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {
  
  @Input()
  public header: string;
  @Input()
  public body: string;

  @ViewChild('template') template: TemplateRef<any>;
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) { }

  openModal() {
    this.modalRef = this.modalService.show(this.template);
  }

  ngOnInit(): void {
  }

}
