import { IVehiculo } from "../../entity/vehiculo.interface";
import { DateUtils } from "../../utils/date.util";
import { IEventoScheduler, OpcionesEventoScheduler, TipoDataEventoScheduler } from "./evento-scheduler.interface";

export abstract class EventoScheduler implements IEventoScheduler {

    static readonly COLOR_DEFAULT = '#626262';

    public id: string;
    public start_date: Date;
    public end_date: Date;
    public text: string;
    public section_id: string;
    public color: string;
    public opciones: OpcionesEventoScheduler;

    public data: any;
    public readonly TIPO_DATA: TipoDataEventoScheduler;

    constructor(
        id: string,
        data: any,
        tipoData: TipoDataEventoScheduler,
        section_id: string,
        text: string,
        colorHex: string,
        opciones: OpcionesEventoScheduler = { modificado: false }
    ) {
        this.id = id;
        this.data = data;
        this.TIPO_DATA = tipoData;
        this.section_id = section_id;
        this.text = text;
        this.opciones = opciones;
        this.color = colorHex ? colorHex : EventoScheduler.COLOR_DEFAULT;

        this.sincronizar();
    }

    public abstract sincronizar();

    public abstract clone();

    public abstract getTemplateTooltip(): string;

    public abstract getTemplateBar(fechaLimiteSchedulerInicial: Date, fechaLimiteSchedulerFinal: Date): string;

    public getSegundosInicialesFueraDeRango(fechaMinima: Date): number {
        let segundosInicialesFueraDeRango = DateUtils.getSegundosEntreFechas(this.start_date, fechaMinima);
        segundosInicialesFueraDeRango = segundosInicialesFueraDeRango >= 0 ? segundosInicialesFueraDeRango : 0;

        return segundosInicialesFueraDeRango;
    }

    public getSegundosFinalesFueraDeRango(fechaMaxima: Date): number {
        let segundosFinalesFueraDeRango = DateUtils.getSegundosEntreFechas(fechaMaxima, this.end_date);
        segundosFinalesFueraDeRango = segundosFinalesFueraDeRango >= 0 ? segundosFinalesFueraDeRango : 0;

        return segundosFinalesFueraDeRango;
    }

    public abstract getOpcionesMenuContextual(): { label: string; value: string; }[];

    public estaSolapadoEnHorario(evento:IEventoScheduler): boolean {

        let estanSolapados = (evento.start_date >= this.start_date && evento.start_date <= this.start_date)
            ||
            (evento.end_date >= this.start_date && evento.end_date <= this.end_date)
            ||
            (this.start_date >= evento.start_date && this.start_date <= evento.end_date)
            ||
            (this.end_date >= evento.start_date && this.end_date <= evento.end_date);

        return estanSolapados;
    }
}
