// Entity
import { IConductor } from '../entity/conductor.interface';
import { IConfiguracion } from '../entity/configuracion.interface';
import { IServicio } from '../entity/servicio.interface';
import { IVehiculo } from '../entity/vehiculo.interface';
import { DateUtils } from '../utils/date.util';
import { LocationUtils } from '../utils/location.util';

export class ServicioBll {

  public static readonly TIPO_NO_SALIDA_LLEGADA = 0;
  public static readonly TIPO_ES_SALIDA = 1;
  public static readonly TIPO_ES_LLEGADA = 2;

  constructor() {  }

  public static getEstimacionMinutosPorKilometroDistanciaEntreServicios(servicio1: IServicio, servicio2: IServicio): number
  {
    if(servicio1.tipoServicio && servicio1.latitudDestino && servicio1.longitudDestino && servicio2.latitudOrigen && servicio2.longitudOrigen) {
      return LocationUtils.getKilometrosEntreCoordenadas(
        servicio1.latitudDestino, servicio1.longitudDestino,
        servicio2.latitudOrigen, servicio2.longitudOrigen
      ) * servicio1.tipoServicio.estimacionMinutosPorKilometroDistanciaEnLineaRecta;
    }

    return 0;
  }

  public static getPrefijoTipo(servicio: IServicio): string
  {
    let prefijo = '';

    switch (servicio.tipo) {
        case 'T': prefijo = 'T'; break;
        case 'D': prefijo = 'D'; break;
        case 'K': prefijo = 'TPD'; break;
    }

    return prefijo;
  }

  public static getNombreTipo(servicio: IServicio): string
  {
    let tipo = '';

    switch (servicio.tipo) {
        case 'T': tipo = 'TRASLADO'; break;
        case 'D': tipo = 'DISPOSICIÓN'; break;
        case 'K': tipo = 'TRASLADO POR DISTANCIA'; break;
    }

    return tipo;
  }

  public static getMinutosEntreServicios = function(servicio1, servicio2)
  {
    // TODO UtilsDate
    return (servicio2.dateFechaInicio.getTime() - servicio1.dateFechaFinConMargen.getTime()) / 1000 / 60;
  }

  public static serviciosSolapadosEnHorario(servicio1: IServicio, servicio2: IServicio): boolean {

    let estanSolapados = (servicio1.dateFechaInicio >= servicio2.dateFechaInicio && servicio1.dateFechaInicio <= servicio2.dateFechaFinConMargen)
            ||
        (servicio1.dateFechaFinConMargen >= servicio2.dateFechaInicio && servicio1.dateFechaFinConMargen <= servicio2.dateFechaFinConMargen)
            ||
        (servicio2.dateFechaInicio >= servicio1.dateFechaInicio && servicio2.dateFechaInicio <= servicio1.dateFechaFinConMargen)
            ||
        (servicio2.dateFechaFinConMargen >= servicio1.dateFechaInicio && servicio2.dateFechaFinConMargen <= servicio1.dateFechaFinConMargen);

    if (!estanSolapados) {
      let servicioAnterior = servicio1;
      let servicioPosterior = servicio2;

      if(servicio1.dateFechaFinConMargen > servicio2.dateFechaInicio) {
        servicioAnterior = servicio2;
        servicioPosterior = servicio1;
      }

      let minutosEntreServicios = this.getMinutosEntreServicios(servicioAnterior, servicioPosterior);
      let minutosEstimadosEntreServicios = this.getEstimacionMinutosPorKilometroDistanciaEntreServicios(servicioAnterior, servicioPosterior);

      estanSolapados = minutosEstimadosEntreServicios > minutosEntreServicios;
    }

    return estanSolapados;
  }

  public static esCompatibleConConductor(servicio: IServicio, conductor: IConductor): boolean {
    let esCompatible = true;

    if (servicio.areaTrabajo && conductor.areaTrabajo && conductor.areaTrabajo.id !== servicio.areaTrabajo.id)
      esCompatible = false;
    else if (servicio.tipoServicio && conductor.permisoConduccion.nivel < servicio.tipoServicio.permisoConduccion.nivel)
      esCompatible = false;
    else if (servicio.idioma && !conductor.idsIdiomas.some((idIdioma) => idIdioma === servicio.idioma.id))
      esCompatible = false;
    
    return esCompatible;
  }

  public static esCompatibleConVehiculo(servicio: IServicio, vehiculo: IVehiculo): boolean {
    let esCompatible = true;

    if (servicio.areaTrabajo && vehiculo.areaTrabajo && vehiculo.areaTrabajo.id !== servicio.areaTrabajo.id)
      esCompatible = false;
    else if (servicio.tipoServicio && vehiculo.permisoConduccion.nivel < servicio.tipoServicio.permisoConduccion.nivel)
      esCompatible = false;
    else if (servicio.tipoServicio && !vehiculo.idsTiposServicioCompatibles.some((idTiposServicioCompatible) => idTiposServicioCompatible === servicio.tipoServicio.id))
      esCompatible = false;
    else if (vehiculo.numPlazas < (servicio.numeroAdultos + servicio.numeroNinyos + servicio.numeroBebes))
      esCompatible = false;
    else if (servicio.color && (!vehiculo.color || vehiculo.color.id != servicio.color.id))
      esCompatible = false;
    
    return esCompatible;
  }

  /* public static crearAgrupacionesAPArtirDeServiciosConDatosAgrupacion(servicios: IServicio[]) {
    let serviciosSinAgrupacion = servicios.filter(servicio => servicio.datosAgrupacionCompartidos == null);
    let serviciosConAgrupacion = servicios.filter(servicio => servicio.datosAgrupacionCompartidos != null);

    let agrupaciones: IServicio[] = [];
    serviciosConAgrupacion.forEach((servicio: IServicio) => {
      let datosAgrupacion = servicio.datosAgrupacionCompartidos;

      if(datosAgrupacion) {
        let agrupacion = agrupaciones.find(agrup => agrup.id === 0);

        if(agrupacion) {
          agrupacion.serviciosAgrupacionCompartidos.push(servicio);
        }
        else {
          let nuevaAgrupacion = cloneDeep(servicio);
          nuevaAgrupacion.esAgrupacionCompartidos = true;
          nuevaAgrupacion.datosAgrupacionCompartidos = null;
          nuevaAgrupacion.serviciosAgrupacionCompartidos = [servicio];
          nuevaAgrupacion.id = 0;

          agrupaciones.push(nuevaAgrupacion);
        }
      }
    });

    if(agrupaciones.length > 0)
      agrupaciones.forEach( agrupacion => this.calcularDatosAgrupacion(agrupacion));

    return serviciosSinAgrupacion.concat(agrupaciones);
  } */

  public static formatearServicioRecibidoDeAPI(servicio: IServicio, configuracion: IConfiguracion) {
    servicio.dateFechaInicio = DateUtils.parseStringToDate(servicio.fechaInicio);
    this.calcularMargenes(servicio, configuracion);

    return servicio;
  }

  public static calcularMargenes(servicio: IServicio, configuracion: IConfiguracion) {
    servicio.margenSegundosInicial = 0;
    servicio.margenSegundosFinal = servicio.tipoServicio?
      servicio.tipoServicio.minimoMinutosEntreServicios * 60:
      0;

    if(servicio.esSalida === this.TIPO_ES_LLEGADA)
      servicio.margenSegundosInicial += configuracion.margenMinutosInicialesLlegadaVuelo * 60;

    this.sincronizarFechasConInicioYMargenes(servicio);
  }

  public static sincronizarFechasString(servicio: IServicio) {
    servicio.fechaInicio = DateUtils.parseDateToString(servicio.dateFechaInicio);
    servicio.fechaFin = DateUtils.parseDateToString(servicio.dateFechaFin);
  }

  public static sincronizarFechasConInicioYMargenes(servicio: IServicio) {
    this.sincronizarFechasString(servicio);

    let newDateFin = new Date(servicio.dateFechaInicio);
    DateUtils.sumarSegundos(newDateFin, servicio.duracionSegundos);
    
    servicio.dateFechaFin = newDateFin;

    servicio.dateFechaFinConMargen = new Date(servicio.dateFechaFin);
    DateUtils.sumarSegundos(servicio.dateFechaFinConMargen, servicio.margenSegundosInicial + servicio.margenSegundosFinal);
  }

  public static tienenDireccionesGoogleMaps(servicio: IServicio) {
    return servicio.latitudOrigen && servicio.longitudOrigen && servicio.latitudDestino && servicio.longitudDestino;
  }

  public static seHaModificado(servicioOld: IServicio, servicioNew: IServicio) {
    return this.getMensajesCambios(servicioOld, servicioNew).length > 0;
  }

  public static getMensajesCambios(servicioOld: IServicio, servicioNew: IServicio): string[] {
    let cambios: string[] = [];
    // Comprobamos campos editables desde la vista de edición
    if (servicioOld.dateFechaInicio.getTime() !== servicioNew.dateFechaInicio.getTime())
      cambios.push('Cambio Fecha inicio');
    if (servicioOld.origen !== servicioNew.origen
        || servicioOld.latitudOrigen !== servicioNew.latitudOrigen
        || servicioOld.longitudOrigen !== servicioNew.longitudOrigen)
      cambios.push('Cambio Origen');
    if (servicioOld.destino !== servicioNew.destino
        || servicioOld.latitudDestino !== servicioNew.latitudDestino
        || servicioOld.longitudDestino !== servicioNew.longitudDestino)
      cambios.push('Cambio Destino');

    // Comprobamos asignaciones de vehiculo y conductor
    if (servicioOld.vehiculo?.id !== servicioNew.vehiculo?.id) {
      if(!servicioOld.vehiculo)
        cambios.push('Asignación Vehículo');
      else if(!servicioNew.vehiculo)
        cambios.push('Eliminación Vehículo');
      else
        cambios.push('Reasignación Vehículo');
    }
    if (servicioOld.idConductores.length !== servicioNew.idConductores.length
        || servicioOld.idConductores.some((idConductorOld) => servicioNew.idConductores.some((idConductorNew) => idConductorNew !== idConductorOld))) {
      if (servicioOld.idConductores.length === 0)
        cambios.push('Asignación Conductor');
      else if(servicioNew.idConductores.length === 0)
        cambios.push('Eliminación Conductor');
      else
        cambios.push('Reasignación Conductor');
    }

    return cambios;
  }

  public static getIconosDatosServicio(servicio: IServicio, formatoLargo: boolean = true): string[] {
    let iconos: string[] = [];

    if (formatoLargo && servicio.esSalida === this.TIPO_ES_LLEGADA)
      iconos.push("<i class='fas fa-plane-arrival icono-dato-servicio'></i>");
    if (formatoLargo && servicio.esSalida === this.TIPO_ES_SALIDA)
      iconos.push("<i class='fas fa-plane-departure icono-dato-servicio'></i>");

    /* if(formatoLargo && servicio.esAgrupacionCompartidos)
      iconos.push("<i class='fas fa-object-group icono-dato-servicio'></i>"); */
    
    if (formatoLargo && servicio.idConductores.length > 0)
      iconos.push("<i class='fas fa-user icono-dato-servicio'></i>");
    else if (formatoLargo)
      iconos.push("<i class='fas fa-user icono-dato-servicio prohibido'></i>");

    if (servicio.vehiculo)
      iconos.push("<i class='fas fa-car icono-dato-servicio'></i>");
    else if (formatoLargo)
      iconos.push("<i class='fas fa-car icono-dato-servicio prohibido'></i>");

    if (!this.tienenDireccionesGoogleMaps(servicio))
      iconos.push("<i class='fab fa-google icono-dato-servicio prohibido'></i>");
    else if (formatoLargo)
      iconos.push("<i class='fab fa-google icono-dato-servicio'></i>");

    return iconos;
  }
}
