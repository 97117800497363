import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID,NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './shared/dashboard/dashboard.module';
import { AsignacionRoutingModule } from './modules/asignacion/asignacion-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BaseUrlInterceptor } from './core/interceptors/base-url-interceptor.service';
import { AuthService } from './modules/auth/services/auth.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';

import { FormsModule } from '@angular/forms';

import { AuthTokenInterceptor } from './core/interceptors/auth-token-interceptor.service';
import { registerLocaleData } from '@angular/common';
// Importar locales
import localePy from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

// Registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localePy, 'es');
registerLocaleData(localeEn, 'en')

// Registramos los locales para los componentes como datepickers
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/chronos';

defineLocale('es', esLocale);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    AsignacionRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ModalModule,
    NgSelectModule, // TODO SOLUCIONAR CREACIÓN DINAMICA DE COMPONENTES
    BsDatepickerModule.forRoot(), // TODO SOLUCIONAR CREACIÓN DINAMICA DE COMPONENTES
    TimepickerModule.forRoot(), // TODO SOLUCIONAR CREACIÓN DINAMICA DE COMPONENTES
    GooglePlaceModule // TODO SOLUCIONAR CREACIÓN DINAMICA DE COMPONENTES
  ],
  providers: [
    BsModalService,
    AuthService,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    { 
      provide: LOCALE_ID, 
      useValue: 'es' 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
