// Entity
import { EventoSchedulerAgrupacion } from '../helper/dhtmlxHelper/evento-scheduler-agrupacion.class';
import { EventoSchedulerServicio } from '../helper/dhtmlxHelper/evento-scheduler-servicio.class';
import { EventoSchedulerReserva } from '../helper/dhtmlxHelper/evento-scheduler-reserva.class';
import { TipoDataEventoScheduler } from '../helper/dhtmlxHelper/evento-scheduler.interface';
import { EventoScheduler } from '../helper/dhtmlxHelper/evento-schedulerx';
import { EventoSchedulerMarcador } from '../helper/dhtmlxHelper/evento-scheduler-marcador.class';

export abstract class EventoSchedulerBll {

  public static fromJsonObject(evento: EventoScheduler): EventoScheduler {
    let objetoFormateado: EventoScheduler = null;

    switch (evento.TIPO_DATA) {
      case TipoDataEventoScheduler.SERVICIO:
        objetoFormateado = new EventoSchedulerServicio(evento.data, evento.section_id, evento.text);
        break;
      case TipoDataEventoScheduler.AGRUPACION:
        objetoFormateado = new EventoSchedulerAgrupacion(evento.data, evento.section_id, evento.text);
        break;
      case TipoDataEventoScheduler.MARCADOR:
        objetoFormateado = new EventoSchedulerMarcador(evento.id, evento.data, evento.section_id, evento.text, evento.start_date, evento.end_date, evento.color);
        break;
    }

    return Object.assign(
      objetoFormateado,
      evento
    );
  }
  
}