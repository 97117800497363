<div class="custom-datepicker-container form-group col-12 row">
    <label class="form-control-label truncate" for="date">{{label}}</label>
    <div class="input-group date luc-date">
        <input type="text" class="form-control" name="date" #dpFechaInicio="bsDatepicker"
            bsDatepicker [(ngModel)]="selectedDate" triggers=""
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue', showWeekNumbers: false }"
            (ngModelChange)="ngModelChange()">
        <span class="input-group-addon fa fa-calendar" (click)="dpFechaInicio.toggle()"></span>
        <span *ngIf="help" class="input-group-addon fa fa-question-circle" (click)="modalHelp.openModal();">
            <evtc-custom-modal #modalHelp [header]="label" [body]="help"></evtc-custom-modal>
        </span>
    </div>
</div>