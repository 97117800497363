import { RutaEventos } from "../../business-logic-layer/asignacion-automatica-servicios/ruta-servicios.class";
import { IAreaTrabajo } from "../../entity/area-trabajo.interface";
import { IConductor } from "../../entity/conductor.interface";
import { ITipoServicio } from "../../entity/tipo-servicio.interface";
import { IVehiculo } from "../../entity/vehiculo.interface";
import { DateUtils } from "../../utils/date.util";
import { LocationUtils } from "../../utils/location.util";
import { IEventoScheduler, OpcionesEventoScheduler, TipoDataEventoScheduler } from "./evento-scheduler.interface";
import { EventoScheduler } from "./evento-schedulerx";

export abstract class EventoSchedulerReserva extends EventoScheduler {

    static readonly COLOR_PREASIGNADO_SIN_CONFIRMAR = '#0000003f';

    static readonly ESTADO_SIN_ASIGNAR = 0;
    static readonly ESTADO_ASIGNADO = 1;
    static readonly ESTADO_PREASIGNADO = 2;

    static readonly CONTEXT_MENU_OPCION_ASIGNAR_VEHICULO = "AsignarVehiculo";
    static readonly CONTEXT_MENU_OPCION_ELIMINAR_VEHICULO = "EliminarrVehiculo";
    static readonly CONTEXT_MENU_OPCION_EDITAR = "Editar";

    public estado: number;

    public data: any;
    public readonly TIPO_DATA: TipoDataEventoScheduler;

    constructor(
        id: string,
        data: any,
        tipoData: TipoDataEventoScheduler,
        section_id: string,
        text: string,
        opciones: OpcionesEventoScheduler = { modificado: false },
        estado: number
    ) {

        super(
            id,
            data,
            tipoData,
            section_id,
            text,
            EventoScheduler.COLOR_DEFAULT,
            opciones
        );

        this.sincronizar();

        if (estado)
            this.estado = estado;
        else if (this.getIdConductores().length > 0)
            this.estado = EventoSchedulerReserva.ESTADO_ASIGNADO;
        else
            this.estado = EventoSchedulerReserva.ESTADO_SIN_ASIGNAR;

        let colorHex = this.getTipoServicio()?.colorHex;
        this.color = colorHex ? colorHex : EventoScheduler.COLOR_DEFAULT;
    }

    public abstract sincronizar();

    public abstract clone();

    public clonePreasignadoConfirmado(newId: string, newSectionId: string) {
        let newEvento = this.clone();

        newEvento.estado = EventoSchedulerReserva.ESTADO_PREASIGNADO;
        newEvento.id = newId;
        newEvento.section_id = newSectionId;

        return newEvento;
    }

    public clonePreasignadoSinConfirmado(newId: string, newSectionId: string) {
        let nuevoEv = this.clonePreasignadoConfirmado(newId, newSectionId);
        nuevoEv.color = EventoSchedulerReserva.COLOR_PREASIGNADO_SIN_CONFIRMAR;
        return nuevoEv;
    }

    public abstract getTemplateTooltip(): string;

    public abstract getTemplateBar(fechaLimiteSchedulerInicial: Date, fechaLimiteSchedulerFinal: Date): string;

    public abstract getCambios(eventoInicial: IEventoScheduler): { id: number, mensajesCambios: string[] };

    public abstract asignarIdsConductores(idConductores: number[]);

    public abstract asignarVehiculo(vehiculo: IVehiculo);

    public abstract getOpcionesMenuContextual(): { label: string; value: string; }[];

    public abstract esCompatibleConVehiculo(vehiculo: IVehiculo): boolean;

    public abstract esCompatibleConConductor(conductor: IConductor): boolean;

    public abstract esCompatibleConRuta(ruta: RutaEventos): boolean;

    public abstract getIdConductores(): number[];

    public abstract getVehiculo(): IVehiculo | null;

    public abstract getTipoServicio(): ITipoServicio | null;

    public abstract getAreaTrabajo(): IAreaTrabajo | null;

    public abstract getDataId(): number;

    public estaSolapadoEnHorario(evento: EventoSchedulerReserva): boolean {

        let estanSolapados = super.estaSolapadoEnHorario(evento);

        if (!estanSolapados) {
            let eventoAnterior = evento;
            let eventoPosterior: EventoSchedulerReserva = this;

            if (evento.end_date > this.start_date) {
                eventoAnterior = this;
                eventoPosterior = evento;
            }

            let minutosEntreEventos = DateUtils.getMinutosEntreFechas(eventoAnterior.end_date, eventoPosterior.start_date);
            let minutosEstimadosEntreServicios = eventoAnterior.getEstimacionMinutosPorKilometroDistanciaSiguienteEvento(eventoPosterior);

            estanSolapados = minutosEstimadosEntreServicios > minutosEntreEventos;
        }

        return estanSolapados;
    }

    public seHaModificado(eventoInicial: IEventoScheduler): boolean {
        return this.getCambios(eventoInicial).mensajesCambios.length > 0;
    }

    public getEstimacionDistanciaKilometrosSiguienteEvento(siguienteEvento: EventoSchedulerReserva): number {
        let destino = this.getCoordenadasDestino();
        let siguienteOrigen = siguienteEvento.getCoordenadasOrigen();

        if (destino.latitud && destino.longitud && siguienteOrigen.latitud && siguienteOrigen.longitud) {
            return LocationUtils.getKilometrosEntreCoordenadas(
                destino.latitud,
                destino.longitud,
                siguienteOrigen.latitud,
                siguienteOrigen.longitud
            );
        }

        return 0;
    }

    public getEstimacionMinutosPorKilometroDistanciaSiguienteEvento(siguienteEvento: EventoSchedulerReserva): number {
        let tipoServicio = this.getTipoServicio();

        return tipoServicio ?
            this.getEstimacionDistanciaKilometrosSiguienteEvento(siguienteEvento) * tipoServicio.estimacionMinutosPorKilometroDistanciaEnLineaRecta :
            0;
    }

    public abstract getCoordenadasOrigen(): { latitud: number; longitud: number; };

    public abstract getCoordenadasDestino(): { latitud: number; longitud: number; };
}
